<script setup lang="ts">
const { t: $t } = useI18n()

const props = defineProps<{
  disallowDuplicateBasePriceSelection?: boolean
}>()

const selectedMachineryTypes = defineModel<string[]>()

// Search
const search = ref('')

// Data
const { rentalPricing } = useQuery()
const { data: machineryTypeOptionsData, isLoading: areMachineryTypesOptionsLoading } = rentalPricing.allMachineryTypeOptions(search)

type MachineryTypeOption = NonNullable<typeof machineryTypeOptionsData['value']>[number]

// Table
const machineryTypeOptionsColumns = computed<TableConfig<MachineryTypeOption>['columns']>(() => [
  {
    type: 'selection',
    disabled: props.disallowDuplicateBasePriceSelection
      ? row => row.rentalBasePrices.length > 0
      : undefined,
  },
  {
    title: 'Gerätetyp',
    key: 'name',
    width: 240,
  },
  {
    title: 'Kategorie',
    key: 'category',
    render: row => $t(`machinery.category.${row.category}`),
  },
])
</script>

<template>
  <TableFilters v-model="search" placeholder="Suchbegriff eingeben (Name)">
    <TableView
      v-model:checked-row-keys="selectedMachineryTypes"
      :data="machineryTypeOptionsData"
      :columns="machineryTypeOptionsColumns"
      :is-loading="areMachineryTypesOptionsLoading"
      :row-key="row => row.id"
      class="mt-4"
    />
  </TableFilters>

  <FormKit
    v-model="selectedMachineryTypes"
    name="machineryTypes"
    type="hidden"
    label="Gerätetypen"
    validation="required"
  />
</template>

<style scoped>
:deep(.n-scrollbar-content) {
  min-width: 100% !important;
}
</style>
