<script setup lang="ts">
import type { UpdateMachineryAccessory } from '~/types'

const props = defineProps<{ payload: UpdateMachineryAccessory, isDetailsPage?: boolean }>()
const emit = defineEmits<{ (e: 'approved', accessory: UpdateMachineryAccessory['data']['accessory']): void }>()
const { cloned } = useCloned(props.payload)
const moveToDetailsPage = () => navigateTo(`/machinery-accessory/${props.payload.data.accessory.id}`)

const formkitPlugins = useFormkitPlugins()
const { isRole } = useAuthorization()
</script>

<template>
  <FormKit
    v-model="cloned.data.accessory"
    type="form"
    :plugins="[formkitPlugins]"
    :submit-label="isDetailsPage ? $t('button.save') : $t('machineryAccessory.confirmForm.submitButtonOutsideDetailsPage')"
    @submit="emit('approved', cloned.data.accessory)"
  >
    <FormKit
      v-if="isRole('admin')"
      id="isReleasedForOnlineSale"
      :label="$t('machineryAccessory.field.isReleasedForOnlineSale.name')"
      type="checkbox"
    />
    <div v-if="!isDetailsPage" class="flex justify-between mb-4">
      <div>
        Alle erhobenen Parameter
      </div>
      <n-button quaternary @click="moveToDetailsPage">
        <template #icon>
          <Icon name="material-symbols:expand-content-rounded" />
        </template>
      </n-button>
    </div>
  </FormKit>
</template>
