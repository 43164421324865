import type { MachineryAccessoryCategory, MachineryCategories, MachineryDrives, OfferType } from '~/types'

export interface FieldFilters {
  categories: MachineryCategories[]
  drives?: MachineryDrives[]
}

// for the machinery popup
const machineryFieldsByMachineType: Record<string, MachineryCategories[]> = {
  liftingHeightInMillimeters: ['forklift'],
  liftingWeight: ['forklift'],
  workHeightInMillimeters: ['platform', 'crane'],
  forkLengthInMillimeters: ['forklift'],
  licensePlateNumber: ['tractor'],
}

// For reception machine form
const machineryHeadingsByMachineCategory: Record<string, FieldFilters> = {
  platform: { categories: ['trailer'] },
  lenkung: { categories: ['trailer'] },
  antrieb: { categories: ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor'] },
  batteriedaten: { categories: ['forklift', 'platform', 'crane'] },
  mastdaten: { categories: ['forklift', 'platform'] },
}

const machineryFieldsByMachineCategory: Record<string, FieldFilters> = {
  loadCenter: { categories: ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'] },
  workHeightInMillimeters: { categories: ['platform', 'crane'] },
  liftingHeightInMillimeters: { categories: ['forklift'] },
  mastOverallHeightInMillimeters: { categories: ['forklift'] },
  freeLiftInMillimeters: { categories: ['forklift'] },
  forkCarriageWidthInMillimeters: { categories: ['forklift'] },
  initialLift: { categories: ['forklift'] },
  outreach: { categories: ['crane'] },
  hookHeight: { categories: ['crane'] },
  basketLoad: { categories: ['platform'] },
  tractionForce: { categories: ['tugger'] },
  liftingWeight: { categories: ['forklift', 'crane', 'constructionMachinery', 'tractor', 'trailer'] },
  seats: { categories: ['tugger'] },
  fem: { categories: ['forklift'] },
  hals: { categories: ['forklift'] },
  terminalWest: { categories: ['forklift'] },
  addOns: { categories: ['forklift'] },
  shaft: { categories: ['forklift'] },
  sideReachInMillimeters: { categories: ['platform', 'forklift'] },
  lengthToForkface: { categories: ['forklift'] },
  totalLengthInMillimeters: { categories: ['platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer', 'forklift'] },
  cabinHeightInMillimeters: { categories: ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'] },
  dimensionHeightInMillimeters: { categories: ['trailer'] },
  overallHeightInMillimeters: { categories: ['platform', 'crane', 'constructionMachinery', 'tugger', 'tractor'] },
  lengthToForkfaceInMillimeters: { categories: ['forklift'] },
  // photo
  cabinInsidePhotos: { categories: ['forklift', 'constructionMachinery', 'tugger', 'tractor', 'crane'] },
  cabinOutsidePhotos: { categories: ['forklift', 'constructionMachinery', 'tugger', 'tractor', 'crane'] },
  tiresPhotos: { categories: ['forklift', 'platform', 'constructionMachinery', 'tugger', 'tractor', 'trailer', 'crane'] },
  batteryPhotos: { categories: ['forklift'], drives: ['electric'] },
  motorPhotos: { categories: ['forklift', 'platform', 'constructionMachinery', 'tugger', 'tractor', 'crane'], drives: ['diesel', 'gas'] },
}

const machineryFieldsNotRequiredByMachineCategory: Record<string, MachineryCategories[]> = {
  cabinInsidePhotos: ['crane'],
  cabinOutsidePhotos: ['crane'],
}

const machineryPropulsionFieldsByMachineCategory: Record<string, FieldFilters> = {
  producerCompanyName: { categories: ['forklift', 'platform', 'constructionMachinery', 'tugger', 'tractor'], drives: ['diesel', 'gas'] },
  typeName: { categories: ['forklift', 'platform', 'constructionMachinery', 'tugger', 'tractor'], drives: ['diesel', 'gas'] },
  gear: { categories: ['tugger', 'platform', 'constructionMachinery', 'forklift'], drives: ['diesel', 'gas'] },
  serialNumber: { categories: ['forklift', 'platform', 'constructionMachinery', 'tugger', 'tractor'], drives: ['diesel', 'gas'] },
  gearProducer: { categories: ['forklift'], drives: ['diesel', 'gas'] },
  hasAdBlue: { categories: ['forklift', 'platform', 'constructionMachinery', 'tugger', 'tractor'], drives: ['diesel', 'gas'] },
}

// For machinery accessory form
const machineryAccessoryFieldsByCategory: Record<string, MachineryAccessoryCategory[]> = {
  transportDimensions: ['miscellaneous', 'abusBogie', 'coilDorn', 'rotaryDevice', 'craneArm', 'fork', 'forkExtension', 'ballast', 'loadDistributionPlates', 'shovel', 'heavyCasters', 'jib', 'ramps', 'forkCarriage', 'sideShifter', 'forkAdjuster', 'stands', 'rollers', 'weights', 'hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'heavyDutyLifter'],

  priceInEuros: ['hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'weights', 'rollers', 'stands', 'forkAdjuster', 'sideShifter', 'forkCarriage', 'ramps', 'jib', 'heavyCasters', 'shovel', 'loadDistributionPlates', 'ballast', 'forkExtension', 'fork', 'craneArm', 'rotaryDevice', 'coilDorn', 'abusBogie', 'miscellaneous', 'heavyDutyLifter', 'basePlate', 'trailer'],
  pricePurchaseEuros: ['internalOperatingEquipment'],

  producerCompanyName: ['basePlate', 'miscellaneous', 'abusBogie', 'coilDorn', 'rotaryDevice', 'craneArm', 'fork', 'forkExtension', 'ballast', 'loadDistributionPlates', 'shovel', 'heavyCasters', 'jib', 'ramps', 'forkCarriage', 'sideShifter', 'forkAdjuster', 'stands', 'rollers', 'weights', 'hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'heavyDutyLifter', 'trailer', 'internalOperatingEquipment'],
  typeName: ['basePlate', 'miscellaneous', 'abusBogie', 'coilDorn', 'rotaryDevice', 'craneArm', 'fork', 'forkExtension', 'ballast', 'loadDistributionPlates', 'shovel', 'heavyCasters', 'jib', 'ramps', 'forkCarriage', 'sideShifter', 'forkAdjuster', 'stands', 'rollers', 'weights', 'hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'heavyDutyLifter', 'trailer', 'storageSpace', 'internalOperatingEquipment'],

  imeiOne: ['internalOperatingEquipment'],
  imeiTwo: ['internalOperatingEquipment'],

  deliveryCompanyName: ['trailer'],
  trailerType: ['trailer'],
  licensePlateNumber: ['trailer'],
  firstRegistrationDate: ['trailer'],
  technicalInspectionDate: ['trailer'],
  securityInspectionDate: ['trailer'],
  actualPayloadInKilograms: ['trailer'],

  serialNumber: ['basePlate', 'miscellaneous', 'abusBogie', 'coilDorn', 'rotaryDevice', 'craneArm', 'fork', 'forkExtension', 'ballast', 'loadDistributionPlates', 'shovel', 'heavyCasters', 'jib', 'ramps', 'forkCarriage', 'sideShifter', 'forkAdjuster', 'stands', 'rollers', 'weights', 'hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'heavyDutyLifter', 'trailer', 'internalOperatingEquipment'],
  lengthInMillimeters: ['basePlate', 'miscellaneous', 'abusBogie', 'coilDorn', 'rotaryDevice', 'craneArm', 'fork', 'forkExtension', 'ballast', 'loadDistributionPlates', 'shovel', 'heavyCasters', 'jib', 'ramps', 'forkCarriage', 'sideShifter', 'forkAdjuster', 'stands', 'rollers', 'weights', 'hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'heavyDutyLifter', 'trailer'],
  widthInMillimeters: ['basePlate', 'miscellaneous', 'abusBogie', 'coilDorn', 'rotaryDevice', 'craneArm', 'fork', 'forkExtension', 'ballast', 'loadDistributionPlates', 'shovel', 'heavyCasters', 'jib', 'ramps', 'forkCarriage', 'sideShifter', 'forkAdjuster', 'stands', 'rollers', 'weights', 'hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'heavyDutyLifter', 'trailer'],
  heightInMillimeters: ['basePlate', 'miscellaneous', 'abusBogie', 'coilDorn', 'rotaryDevice', 'craneArm', 'fork', 'forkExtension', 'ballast', 'loadDistributionPlates', 'shovel', 'heavyCasters', 'jib', 'ramps', 'forkCarriage', 'sideShifter', 'forkAdjuster', 'stands', 'rollers', 'weights', 'hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'heavyDutyLifter', 'trailer'],

  fem: ['abusBogie', 'fork', 'forkCarriage', 'sideShifter', 'forkAdjuster', 'rotaryDevice', 'shovel'],
  yearBuilt: ['basePlate', 'abusBogie', 'coilDorn', 'rotaryDevice', 'craneArm', 'fork', 'forkExtension', 'ballast', 'loadDistributionPlates', 'shovel', 'heavyCasters', 'jib', 'ramps', 'forkCarriage', 'sideShifter', 'forkAdjuster', 'stands', 'rollers', 'hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'workBasket', 'clipOnBasket', 'charger', 'heavyDutyLifter', 'trailer'],
  liftingWeight: ['basePlate', 'miscellaneous', 'abusBogie', 'coilDorn', 'rotaryDevice', 'craneArm', 'fork', 'forkExtension', 'ballast', 'loadDistributionPlates', 'shovel', 'heavyCasters', 'jib', 'ramps', 'forkCarriage', 'sideShifter', 'forkAdjuster', 'stands', 'rollers', 'hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'workBasket', 'clipOnBasket', 'heavyDutyLifter'],
  weightInKilograms: ['basePlate', 'miscellaneous', 'abusBogie', 'coilDorn', 'rotaryDevice', 'craneArm', 'fork', 'forkExtension', 'ballast', 'loadDistributionPlates', 'shovel', 'heavyCasters', 'jib', 'ramps', 'sideShifter', 'forkAdjuster', 'stands', 'rollers', 'weights', 'hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'workBasket', 'clipOnBasket', 'charger', 'wheels', 'heavyDutyLifter', 'trailer'],
  neck: ['fork'],

  color: ['wheels'],
  tireType: ['wheels'],
  wheelSize: ['wheels'],
  compatibleMachineryProducer: ['wheels'],

  forkLength: ['forkAdjuster'],
  forkWidth: ['forkAdjuster'],
  forkHeight: ['forkAdjuster'],
  distanceInnerEdgeToInnerEdge: ['sideShifter', 'forkAdjuster', 'rotaryDevice'],
  distanceOuterEdgeToOuterEdge: ['sideShifter', 'forkAdjuster', 'rotaryDevice'],

  cubicMeters: ['shovel', 'forkAdjuster'],
  loadCenter: ['fork'],

  retractionLugsWidth: ['forkExtension', 'clipOnHook', 'clipOnBasket'],
  retractionLugsHeight: ['forkExtension', 'clipOnHook', 'clipOnBasket'],

  retractionLugsDistanceInnerEdgeToInnerEdge: ['clipOnHook', 'clipOnBasket'],
  retractionLugsDistanceOuterEdgeToOuterEdge: ['clipOnHook', 'clipOnBasket'],

  description: ['miscellaneous', 'heavyDutyLifter', 'trailer', 'storageSpace', 'internalOperatingEquipment'],
  machineryTypes: ['hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'charger', 'forkAdjuster', 'sideShifter', 'forkCarriage', 'jib', 'shovel', 'ballast', 'forkExtension', 'fork', 'craneArm', 'miscellaneous', 'abusBogie'],

  volt: ['charger'],

  liftingHeight: ['heavyDutyLifter'],
  componentParts: ['heavyDutyLifter', 'internalOperatingEquipment'],
  drive: ['heavyDutyLifter'],
  lengthLeverLink: ['heavyDutyLifter'],
  widthLeverLink: ['heavyDutyLifter'],
  powerLeverLink: ['heavyDutyLifter'],

  innerLength: ['basePlate'],
  innerWidth: ['basePlate'],

  productCode: ['hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'weights', 'rollers', 'stands', 'forkAdjuster', 'sideShifter', 'forkCarriage', 'ramps', 'jib', 'heavyCasters', 'shovel', 'loadDistributionPlates', 'ballast', 'forkExtension', 'fork', 'craneArm', 'rotaryDevice', 'coilDorn', 'abusBogie', 'miscellaneous', 'heavyDutyLifter', 'basePlate'],
  condition: ['hookShort', 'hookLong', 'clipOnHook', 'latticeMastWinch', 'winch', 'wheels', 'workBasket', 'clipOnBasket', 'charger', 'weights', 'rollers', 'stands', 'forkAdjuster', 'sideShifter', 'forkCarriage', 'ramps', 'jib', 'heavyCasters', 'shovel', 'loadDistributionPlates', 'ballast', 'forkExtension', 'fork', 'craneArm', 'rotaryDevice', 'coilDorn', 'abusBogie', 'miscellaneous', 'heavyDutyLifter', 'basePlate', 'trailer', 'internalOperatingEquipment'],

  vehicleRegistrationPhoto: ['trailer'],
}

// For machinery accessory public details page
const machineryAccessoryPublicFieldsByCategory: Record<string, MachineryAccessoryCategory[]> = {
  producerCompanyName: ['forkAdjuster', 'heavyCasters', 'heavyDutyLifter', 'loadDistributionPlates', 'winch'],
  typeName: ['forkAdjuster', 'heavyCasters', 'heavyDutyLifter', 'loadDistributionPlates', 'winch'],
  yearBuilt: ['winch'],

  liftingWeight: ['fork', 'forkAdjuster', 'clipOnHook', 'heavyCasters', 'heavyDutyLifter', 'winch'],

  lengthInMillimeters: ['heavyCasters', 'heavyDutyLifter', 'loadDistributionPlates'],
  widthInMillimeters: ['heavyCasters', 'heavyDutyLifter', 'loadDistributionPlates'],
  heightInMillimeters: ['heavyCasters', 'heavyDutyLifter', 'loadDistributionPlates'],

  distanceInnerEdgeToInnerEdge: ['forkAdjuster'],
  distanceOuterEdgeToOuterEdge: ['forkAdjuster'],

  retractionLugsWidth: ['clipOnHook'],
  retractionLugsHeight: ['clipOnHook'],
  retractionLugsDistanceInnerEdgeToInnerEdge: ['clipOnHook'],
  retractionLugsDistanceOuterEdgeToOuterEdge: ['clipOnHook'],

  lengthLeverLink: ['heavyDutyLifter'],
  widthLeverLink: ['heavyDutyLifter'],
  powerLeverLink: ['heavyDutyLifter'],
}

const logisticsDetailsFieldsByMachineCategory: Record<string, FieldFilters> = {
  comment: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  generalPhotos: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  innerCabinPhotos: { categories: ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  outerCabinPhotos: { categories: ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  tiresPhotos: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  enginePhotos: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
}

const logisticsMachineryDetailsFieldsByMachineCategory: Record<string, FieldFilters> = {
  tankFilling: { categories: ['forklift', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas'] },
  adblueFilling: { categories: ['forklift', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel'] },
  isTankFull: { categories: ['forklift', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas'] },
  operatingHours: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  uvvValiditedAt: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  ignitionKeys: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  gasTanks: { categories: ['forklift', 'crane'], drives: ['gas', 'electric'] },
  emptyGasTanks: { categories: ['forklift', 'crane'], drives: ['gas', 'electric'] },
  tuvValiditedAt: { categories: [] },
  securityCheckValiditedAt: { categories: [] },
}

const logisticsFunctionalDetailsFieldsByMachineCategory: Record<string, FieldFilters> = {
  areEngineAndGearboxWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  isBreakWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  isSteerwheelWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  areMastAndChainAndBearingWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  isElectricalSystemWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  isMachineryAccessory: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  isSecurityEquipmentWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  isLightingWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  isBatteryWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  areWarningLightAndHornWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  isSafetyShutdownWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  isHydraulicSystemWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  isGpsWorking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  isBatteriesAvailable: { categories: ['crane'] },
  note: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
}

const logisticsVisualDetailsFieldsByMachineCategory: Record<string, FieldFilters> = {
  isMachineryComplete: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  hasExternalDamage: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  isLeaking: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  isOilLevelOk: { categories: ['forklift', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas'] },
  isCoolantLevelOk: { categories: ['forklift', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas'] },
  isMachineryCleaned: { categories: ['forklift', 'crane', 'trailer'], drives: ['gas', 'electric', 'noDrive'] },
  pollution: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  isTapeMeasureAvailable: { categories: ['forklift', 'crane'], drives: ['electric'] },
  note: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
}

const logisticsConditionDetailsFieldsByMachineCategory: Record<string, FieldFilters> = {
  roofAndCabin: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  tiresAirAndSE: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  forkesAndShovels: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  painting: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
  driverSeat: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor'], drives: ['diesel', 'gas', 'electric'] },
  note: { categories: ['forklift', 'crane', 'constructionMachinery', 'platform', 'tugger', 'tractor', 'trailer'], drives: ['diesel', 'gas', 'electric', 'noDrive'] },
}

const offerCreateOrEditByOfferType: Record<string, OfferType[]> = {
  title: ['service-project'],
  description: ['service-project'],
  contactPersonId: ['rental', 'sale', 'special', 'service-project'],
  paymentCondition: ['rental', 'sale', 'special', 'service-project'],
  obligationEndsAt: ['rental', 'service-project', 'special'],
  rentalDuration: ['rental', 'service-project'],
  editRentalDays: ['rental'],
  editBreakDays: ['service-project'],
  doesCustomerDoTask: ['rental', 'sale'],
  allowRentalPauseCreation: ['rental'],
  claimingPartner: ['rental', 'sale', 'special', 'service-project'],
  customerOrderNumber: ['rental', 'sale', 'special', 'service-project'],
  requestedAt: ['rental', 'sale', 'special'],
  deliveryAt: ['rental', 'sale', 'special'],
  isStorageSpaceOffer: ['special'],
  projectCode: ['special'],
}

const configs = {
  machineryFieldsByMachineType,
  machineryHeadingsByMachineCategory,
  machineryFieldsByMachineCategory,
  machineryPropulsionFieldsByMachineCategory,
  machineryAccessoryFieldsByCategory,
  machineryAccessoryPublicFieldsByCategory,
  logisticsDetailsFieldsByMachineCategory,
  logisticsMachineryDetailsFieldsByMachineCategory,
  logisticsFunctionalDetailsFieldsByMachineCategory,
  logisticsVisualDetailsFieldsByMachineCategory,
  logisticsConditionDetailsFieldsByMachineCategory,
  machineryFieldsNotRequiredByMachineCategory,
  offerCreateOrEditByOfferType,
}

/**
 * Determine whether a machinery-field should be displayed based on the machinery `category` and `drive`.
 */
export function useShowFieldByFilters(filters: Record<string, FieldFilters>, field: string, category: MachineryCategories, drive?: MachineryDrives): boolean {
  // 1. If no configuration for the field exists we want to hide it
  if (!Object.keys(filters).includes(field)) {
    return true
  }

  const { categories, drives } = filters[field]
  const isCategoryIncluded = categories.includes(category)

  /**
   * 2. If:
   * - a drive was provided,
   * - a drives config was provided AND
   * - the machinery is a `forklift` or `platform` we want to make sure that both drive & category match
   */
  if (
    drive && drives
    && ['forklift', 'platform'].includes(category)
  ) {
    return !!(isCategoryIncluded && drives.includes(drive))
  }

  // 3. Otherwise only category matching is ok
  return isCategoryIncluded
}

export default () => configs
