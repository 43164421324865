<script setup lang="ts">
import { createLocalStoragePlugin } from '@formkit/addons'
import type { LocalStorageOptions } from '@formkit/addons'
import type { ApiMachineryTypeGetAll, UpdateOrCreateMachineryAccessory } from '~/types'
import { machineryAccessoryConditions, machineryColors, machineryFems } from '~/server/schemas'
import { machineryFemsAndFive, machineryFemsAndNone } from '~/server/schemas/machineryAndMachineryAccessoryCommon'
import { machineryAccessoryTrailerTypesToGerman } from '~/translations'

const props = defineProps<{ payload: UpdateOrCreateMachineryAccessory, machineryTypes: ApiMachineryTypeGetAll[], localStorageKey: LocalStorageOptions, showStorageLocation?: boolean, showInspectionDates?: boolean, onSave: (payload: UpdateOrCreateMachineryAccessory) => Promise<unknown> }>()

const machineryTypeOptions = computed(() => props.machineryTypes.map(type => ({
  label: `${type.name}`,
  value: type.id,
})))

const tireTypes = ['Luft', 'SE', 'Waben', 'Vollgumi']
const customTireType = ref(
  'tireType' in props.payload.data.accessory
  && !!props.payload.data.accessory.tireType
  && !tireTypes.includes(props.payload.data.accessory.tireType),
)

const formkitPlugins = useFormkitPlugins()

const category = computed(() => props.payload.data.accessory.category)

const femOptions = computed(() => category.value === 'rotaryDevice'
  ? machineryFemsAndNone.options
  : category.value === 'fork'
    ? machineryFemsAndFive.options
    : machineryFems.options)

const trailerTypeOptions = Object.entries(machineryAccessoryTrailerTypesToGerman).map(([englishName, germanName]) => ({ label: germanName, value: englishName }))

const { machineryAccessoryFieldsByCategory: showMachineryFieldsByMachineryAccessory } = useFormOptionalConditions()
</script>

<template>
  <FormKit
    v-slot="{ value }"
    :value="payload.data"
    name="MachineryAccessoryCreationCreateOrEditForm"
    type="form"
    :plugins="[formkitPlugins, createLocalStoragePlugin(localStorageKey)]"
    use-local-storage
    submit-label="Anbaugerät speichern"
    @submit="(data: UpdateOrCreateMachineryAccessory['data']) => onSave({ mode: payload.mode, data } as UpdateOrCreateMachineryAccessory)"
  >
    <FormKit id="accessory" type="group">
      <TheDevOnlyNiceJson :value="value" />

      <div class="flex space-x-2">
        <FormKit
          v-if="showStorageLocation"
          id="storageLocation"
          type="text"
          :label="category !== 'storageSpace' ? 'Lagerplatz' : 'Ort'"
          placeholder="Crailsheim"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.priceInEuros.includes(category)"
          id="priceInEuros"
          type="number"
          step="0.01"
          label="Preis (EUR)"
          placeholder="20"
        />
      </div>

      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.productCode.includes(category)"
          id="productCode"
          type="text"
          label="Artikelnummer"
          placeholder="520"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.condition.includes(category)"
          id="condition"
          type="select"
          validation="required"
          label="Zustand"
          :options="machineryAccessoryConditions"
        />
      </div>

      <FormKit
        v-if="showMachineryFieldsByMachineryAccessory.fem.includes(category)"
        id="fem"
        type="select"
        validation="required"
        :label="$t('machineryAccessory.createOrEditForm.fem')"
        :options="femOptions"
      />

      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.tireType.includes(category)"
          id="color"
          type="select"
          label="Farbe"
          :options="machineryColors"
        />
        <div v-if="showMachineryFieldsByMachineryAccessory.tireType.includes(category)" class="flex items-center gap-2 w-full">
          <FormKit
            v-if="!customTireType"
            id="tireType"
            type="select"
            label="Art"
            :options="tireTypes"
          />
          <FormKit
            v-else
            id="tireType"
            type="text"
            placeholder="Luft"
            label="Art"
          />
          <n-button text @click="customTireType = !customTireType">
            <Icon v-if="!customTireType" name="material-symbols:edit-square-outline-rounded" size="22px" />
            <Icon v-else name="material-symbols:close-rounded" size="22px" />
          </n-button>
        </div>
      </div>

      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.producerCompanyName.includes(category)"
          id="producerCompanyName"
          type="text"
          :validation="category !== 'miscellaneous' ? 'required' : undefined"
          label="Hersteller"
          placeholder="Beispiel GmbH"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.typeName.includes(category)"
          id="typeName"
          type="text"
          :validation="category !== 'miscellaneous' ? 'required' : undefined"
          :label="category !== 'storageSpace' ? 'Typ' : 'Title'"
          placeholder="500"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.deliveryCompanyName.includes(category)"
          id="deliveryCompanyName"
          type="text"
          label="Lieferant"
          placeholder="Beispiel GmbH"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.trailerType.includes(category)"
          id="trailerType"
          type="select"
          validation="required"
          label="Trailer-Art"
          :options="trailerTypeOptions"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.licensePlateNumber.includes(category)"
          id="licensePlateNumber"
          type="text"
          label="Kennzeichen"
          placeholder="H OS 1234"
        />
        <FormKitDate
          v-if="showMachineryFieldsByMachineryAccessory.firstRegistrationDate.includes(category)"
          id="firstRegistrationDate"
          label="Erstzulassung"
          placeholder="2010"
        />
      </div>
      <div class="flex space-x-2">
        <FormKitDate
          v-if="showMachineryFieldsByMachineryAccessory.technicalInspectionDate.includes(category) && showInspectionDates"
          id="technicalInspectionDate"
          label="TÜV"
          placeholder="2010"
        />
        <FormKitDate
          v-if="showMachineryFieldsByMachineryAccessory.securityInspectionDate.includes(category) && showInspectionDates"
          id="securityInspectionDate"
          label="Sicherheitsprüfung"
          placeholder="2010"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.serialNumber.includes(category)"
          id="serialNumber"
          type="text"
          :validation="category !== 'miscellaneous' ? 'required' : undefined"
          label="Seriennummer"
          placeholder="500"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.yearBuilt.includes(category)"
          id="yearBuilt"
          type="number"
          validation="min:1990"
          label="Baujahr"
          placeholder="2010"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.imeiOne.includes(category)"
          id="imeiOne"
          type="text"
          label="IMEI 1"
          placeholder="356303482509450"
          validation="matches:/^[0-9]{15}$/"
          :validation-messages="{
            matches: 'Die IMEI sollte 15 Ziffern lang sein und darf keine Sonder- und Leerzeichen enthalten.',
          }"
          validation-visibility="blur"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.imeiTwo.includes(category)"
          id="imeiTwo"
          type="text"
          label="IMEI 2"
          placeholder="356303482509450"
          validation="matches:/^[0-9]{15}$/"
          :validation-messages="{
            matches: 'Die IMEI sollte 15 Ziffern lang sein und darf keine Sonder- und Leerzeichen enthalten.',
          }"
          validation-visibility="blur"
        />
      </div>
      <FormKit
        v-if="showMachineryFieldsByMachineryAccessory.wheelSize.includes(category)"
        id="wheelSize"
        type="text"
        label="Größe"
        placeholder="100"
        validation="required"
      />
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.liftingWeight.includes(category)"
          id="liftingWeight"
          type="number"
          step="0.01"
          :validation="category !== 'miscellaneous' ? 'required' : undefined"
          label="Tragkraft (kg)"
          placeholder="540"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.lengthInMillimeters.includes(category)"
          id="lengthInMillimeters"
          type="number"
          step="0.01"
          :validation="category !== 'miscellaneous' ? 'required' : undefined"
          label="Länge (mm)"
          placeholder="500"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.widthInMillimeters.includes(category)"
          id="widthInMillimeters"
          type="number"
          step="0.01"
          :validation="category !== 'miscellaneous' ? 'required' : undefined"
          label="Breite (mm)"
          placeholder="500"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.heightInMillimeters.includes(category)"
          id="heightInMillimeters"
          type="number"
          step="0.01"
          :validation="category !== 'miscellaneous' ? 'required' : undefined"
          label="Höhe (mm)"
          placeholder="500"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.weightInKilograms.includes(category)"
          id="weightInKilograms"
          type="number"
          step="0.01"
          :validation="category !== 'miscellaneous' ? 'required' : undefined"
          label="Gewicht (kg)"
          placeholder="66"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.actualPayloadInKilograms.includes(category)"
          id="actualPayloadInKilograms"
          type="number"
          number="integer"
          validation="min:0"
          label="Tatsächliche Zuladung (kg)"
          placeholder="66"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.neck.includes(category)"
          id="neck"
          type="select"
          validation="required"
          label="Hals"
          :options="['A', 'B']"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.cubicMeters.includes(category)"
          id="cubicMeters"
          type="number"
          step="0.01"
          validation="required"
          label="Cbm"
          placeholder="300"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.loadCenter.includes(category)"
          id="loadCenter"
          type="number"
          :label="$t('machineryAccessory.createOrEditForm.loadCenter')"
          placeholder="600"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.forkLength.includes(category)"
          id="forkLength"
          type="number"
          step="0.01"
          validation="required"
          label="Gabellänge (mm)"
          placeholder="1600"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.forkWidth.includes(category)"
          id="forkWidth"
          type="number"
          step="0.01"
          validation="required"
          label="Gabelbreite (mm)"
          placeholder="150"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.forkHeight.includes(category)"
          id="forkHeight"
          type="number"
          step="0.01"
          validation="required"
          label="Gabelstärke (mm)"
          placeholder="40"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.distanceInnerEdgeToInnerEdge.includes(category)"
          id="distanceInnerEdgeToInnerEdge"
          type="number"
          step="0.01"
          validation="required"
          label="IK-IK (mm)"
          placeholder="2000"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.distanceOuterEdgeToOuterEdge.includes(category)"
          id="distanceOuterEdgeToOuterEdge"
          type="number"
          step="0.01"
          validation="required"
          label="AK-AK (mm)"
          placeholder="1700"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.retractionLugsWidth.includes(category)"
          id="retractionLugsWidth"
          type="number"
          step="0.01"
          validation="required"
          label="Einfahrlaschen-Breite (mm)"
          placeholder="150"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.retractionLugsHeight.includes(category)"
          id="retractionLugsHeight"
          type="number"
          step="0.01"
          validation="required"
          label="Einfahrlaschen-Höhe (mm)"
          placeholder="40"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.retractionLugsDistanceInnerEdgeToInnerEdge.includes(category)"
          id="retractionLugsDistanceInnerEdgeToInnerEdge"
          type="number"
          step="0.01"
          validation="required"
          label="Einfahrlaschen-IK-IK (mm)"
          placeholder="2000"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.retractionLugsDistanceOuterEdgeToOuterEdge.includes(category)"
          id="retractionLugsDistanceOuterEdgeToOuterEdge"
          type="number"
          step="0.01"
          validation="required"
          label="Einfahrlaschen-AK-AK (mm)"
          placeholder="1700"
        />
      </div>
      <FormKit
        v-if="showMachineryFieldsByMachineryAccessory.description.includes(category)"
        id="description"
        type="text"
        label="Beschreibung"
        placeholder="Objektbeschreibung..."
      />
      <div
        v-if="showMachineryFieldsByMachineryAccessory.transportDimensions.includes(category)"
        class="grid grid-cols-2 gap-x-2"
      >
        <FormKit
          id="transportLengthInMillimeters"
          type="number"
          step="0.01"
          validation="required"
          label="Transport-Länge (mm)"
          placeholder="150"
        />
        <FormKit
          id="transportWidthInMillimeters"
          type="number"
          step="0.01"
          validation="required"
          label="Transport-Breite (mm)"
          placeholder="40"
        />
        <FormKit
          id="transportHeightInMillimeters"
          type="number"
          step="0.01"
          validation="required"
          label="Transport-Höhe (mm)"
          placeholder="40"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.liftingHeight.includes(category)"
          id="liftingHeight"
          type="number"
          step="0.01"
          validation="required"
          label="Hubhöhe"
          placeholder="2000"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.componentParts.includes(category)"
          id="componentParts"
          type="text"
          validation="required"
          label="Zubehör"
          placeholder="Schrauben"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.drive.includes(category)"
          id="drive"
          type="text"
          validation="required"
          label="Antrieb"
          placeholder="2000"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.lengthLeverLink.includes(category)"
          id="lengthLeverLink"
          type="number"
          step="0.01"
          validation="required"
          label="Länge Hebelasche (mm)"
          placeholder="1700"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.widthLeverLink.includes(category)"
          id="widthLeverLink"
          type="number"
          step="0.01"
          validation="required"
          label="Breite Hebelasche (mm)"
          placeholder="2000"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.powerLeverLink.includes(category)"
          id="powerLeverLink"
          type="number"
          step="0.01"
          validation="required"
          label="Stärke Hebelasche (mm)"
          placeholder="2000"
        />
      </div>
      <div class="flex space-x-2">
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.innerLength.includes(category)"
          id="innerLength"
          type="number"
          step="0.01"
          validation="required"
          label="Länge innen (mm)"
          placeholder="2000"
        />
        <FormKit
          v-if="showMachineryFieldsByMachineryAccessory.innerWidth.includes(category)"
          id="innerWidth"
          type="number"
          step="0.01"
          validation="required"
          label="Breite innen (mm)"
          placeholder="2000"
        />
      </div>
    </FormKit>

    <FormKitMultiSelect
      v-if="category === 'wheels'"
      id="machineryTypes"
      label="Kompatible Gerätetypen"
      :multi-select-options="machineryTypeOptions"
      validation="required"
    />

    <FormKit id="accessory" type="group">
      <FormKit
        v-if="showMachineryFieldsByMachineryAccessory.volt.includes(category)"
        id="volt"
        type="text"
        label="Volt"
        placeholder="14.8"
        validation="required"
      />
      <FormKit
        v-if="showMachineryFieldsByMachineryAccessory.compatibleMachineryProducer.includes(category)"
        id="compatibleMachineryProducer"
        type="text"
        label="Kompatibler Maschinen Hersteller"
        placeholder="Toyota"
        validation="required"
      />
      <div class="flex space-x-2">
        <FormKit id="comment" type="text" label="Kommentar" placeholder="Typ D 48V/100A Stecker 320A" />
      </div>
      <FormKitFileDropzone
        v-if="showMachineryFieldsByMachineryAccessory.vehicleRegistrationPhoto.includes(category)"
        id="vehicleRegistrationPhoto"
        name="vehicleRegistrationPhoto"
        label="Fotos Fahrzeugschein"
        wrap-path-in-object
      />
      <FormKitFileDropzone
        id="photos"
        name="photos"
        label="Anbaugerät Fotos"
        validation="required"
        :validation-messages="{
          required: 'Bitte laden Sie mindestens eine Datei hoch',
        }"
        wrap-path-in-object
      />
    </FormKit>
  </FormKit>
</template>
