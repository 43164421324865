<script setup lang="ts" generic="S extends boolean, T extends Record<string, any>">
import type { DataTableColumns, DataTableCreateSummary, DataTableRowKey, PaginationProps } from 'naive-ui'
import type { RowData } from 'naive-ui/es/data-table/src/interface'
import { NButton } from 'naive-ui'

const props = withDefaults(defineProps<{
  data: S extends true ? undefined : T[]
  hasFavouriteColumn?: boolean
  actionButtons?: ((row: T) => Action[])
  frontActionButtons?: ((row: T) => Action[])
  isLoading: boolean
  columns: DataTableColumns<T>
  singleLine?: boolean
  rowProps?: (rowData: T, rowIndex: number) => object
  rowClassName?: (rowData: T) => string
  rowKey?: (row: T) => string | number
  checkedRowKeys?: DataTableRowKey[]
  cascade?: boolean
  defaultExpandAll?: boolean
  striped?: boolean
  flexHeight?: boolean
  maxHeight?: number | string
  showSummary?: boolean
  scrollX?: number
  isPaginated?: boolean
  initialPaginationSettings?: PaginationProps
  controlledPaginationSettings?: PaginationProps
  isServerSidePaginated?: boolean
}>(), {
  rowProps: undefined,
  rowClassName: undefined,
  actionButtons: undefined,
  frontActionButtons: undefined,
  singleLine: true,
  rowKey: undefined,
  checkedRowKeys: () => [],
  cascade: true,
  defaultExpandAll: false,
  isPaginated: true,
  initialPaginationSettings: () => ({} satisfies PaginationProps),
  striped: true,
  flexHeight: false,
  maxHeight: undefined,
  showSummary: true,
  scrollX: 2100,
  controlledPaginationSettings: undefined,
  isServerSidePaginated: false,
})
const emit = defineEmits<{
  (e: Action, entity: T): void
  (e: 'update:checked-row-keys', keys: DataTableRowKey[], rows: T[], meta: { row?: T, action: 'check' | 'uncheck' | 'checkAll' | 'uncheckAll' }): void
  (e: 'update:page', page: number): void
  (e: 'update:page-size', page: number): void
}>()

const actionButtonColumn = []
if (props.actionButtons) {
  const renderButtonPresets = useRenderButtonPresets()

  const actionButtonEmit = (e: Event, action: Action, row: T) => {
    e.stopImmediatePropagation()
    emit(action, row)
  }

  actionButtonColumn.push({
    title: 'Aktionen',
    key: 'actions',
    render: (row: T) => {
      const actionButtonMaker = props.actionButtons
      if (!actionButtonMaker) {
        return
      }

      const buttons = actionButtonMaker(row).map(action => useRenderButton(renderButtonPresets[action], (e: Event) => actionButtonEmit(e, action, row)))
      return h('div', { class: 'flex space-x-1' }, buttons)
    },
    minWidth: 150,
  })
}

function favouriteButtonEmit(e: Event, row: T) {
  e.stopImmediatePropagation()
  emit('updateFavorite' satisfies Action, row)
}

const favouriteColumn = {
  key: 'isFavorite',
  render: (row: T) => {
    const icon = row.isFavorite ? 'material-symbols:star-rounded' : 'material-symbols:star-outline-rounded'
    const button = h(NButton, { size: 'small', bordered: false, circle: true, onClick: (e: Event) => favouriteButtonEmit(e, row) }, () => useRenderIcon({ name: icon }))
    return button
  },
  minWidth: 50,
}

const frontActionColumn = []
if (props.frontActionButtons) {
  const renderButtonPresets = useRenderButtonPresets()

  const actionButtonEmit = (e: Event, action: Action, row: T) => {
    e.stopImmediatePropagation()
    emit(action, row)
  }

  frontActionColumn.push({
    title: '',
    key: 'frontActions',
    render: (row: T) => {
      const actionButtonMaker = props.frontActionButtons
      if (!actionButtonMaker) {
        return
      }

      const buttons = actionButtonMaker(row).map(action => useRenderButton(renderButtonPresets[action], (e: Event) => actionButtonEmit(e, action, row)))
      return h('div', { class: 'flex space-x-1' }, buttons)
    },
    minWidth: 100,
  })
}

const assembledColumns: DataTableColumns<T> = [
  ...(props.hasFavouriteColumn ? [favouriteColumn] : []),
  ...(frontActionColumn.length > 0 ? frontActionColumn : []),
  ...props.columns,
  ...actionButtonColumn,
]

const summary: DataTableCreateSummary = (data) => {
  const columnNames = assembledColumns.map((c) => {
    // @ts-expect-error `.key` exists, for some reason the naive-ui datatype does not expose it
    return c.key as string
  })

  // Due to an open bug we need to make sure that the summary column exists for every column-key, even if we only want one summary column that spans all columns (as we do here). Issue: https://github.com/tusen-ai/naive-ui/issues/4684
  const summaryColumns = Object.fromEntries(columnNames.map((c) => {
    let summaryText = ''
    if (props.isServerSidePaginated) {
      const totalPageCount = props.controlledPaginationSettings?.pageCount ?? 'unbekannt'
      const countOnCurrentPage = data.length

      summaryText = totalPageCount === 1 ? `Eine Seite, ${countOnCurrentPage} Einträge auf dieser Seite` : `${totalPageCount} Seiten, ${countOnCurrentPage} Einträge auf dieser Seite`
    } else {
      summaryText = data.length === 1 ? '1 Eintrag, einer auf dieser Seite' : `${props.data?.length} Einträge, ${data?.length} auf dieser Seite`
    }

    return [c, {
      value: h('div', { class: 'text-gray-500 italic' }, summaryText),
      colSpan: columnNames.length,
    }]
  }))

  return summaryColumns
}

// Either use controlled pagination settings or build them ourselves
const paginationProps = props.isPaginated
  ? props.controlledPaginationSettings
    ? props.controlledPaginationSettings
    : useTablePagination(props.initialPaginationSettings).serverSidePaginationTableProps as PaginationProps
  : false
</script>

<template>
  <n-data-table
    size="small"
    :columns="assembledColumns"
    :data="data as RowData[]"
    :remote="isServerSidePaginated"
    :pagination="paginationProps"
    :row-props="rowProps"
    :loading="isLoading"
    :scroll-x="scrollX"
    :single-line="singleLine"
    class="overflow-auto"
    :row-class-name="rowClassName"
    :striped="striped"
    :row-key="rowKey"
    :checked-row-keys="checkedRowKeys"
    :cascade="cascade"
    :default-expand-all="defaultExpandAll"
    :flex-height="flexHeight"
    summary-placement="top"
    :max-height="maxHeight"
    :summary="showSummary ? summary : undefined"
    @update:checked-row-keys="(keys, rows, meta) => emit('update:checked-row-keys', keys, rows as T[], { ...meta, row: meta.row as T })"
    @update:page-size="size => emit('update:page-size', size)"
    @update:page="page => emit('update:page', page)"
  />
</template>

<style scoped>
:deep(.n-data-table-tr.no-stripe) > .n-data-table-td:not(.n-data-table-td--summary){
  background-color: transparent;
}
</style>
