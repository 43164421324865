import type { z } from 'zod'
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import type { RentalDay } from '@prisma/client'
import type { DataTableRowKey } from 'naive-ui'
import type { Router } from './server/trpc/routers'
import type { getTasksWithDetailsForDispatcher } from './server/trpc/routers/logisticsPlanning'
import type { createInternalCostPosition, updateInternalCostPosition } from './server/trpc/routers/internalCostPosition'
import type { createDefectSchema, updateDefectSchema } from './server/trpc/routers/defect'
import type { createItemSetSchema, updateItemSetSchema } from './server/trpc/routers/itemSet'
import type { createSpecialPositionsSchema, updateSpecialPositionsSchema } from './server/trpc/routers/offerPosition/special'
import type { terminateRentalPositionsSchema, updateNotReturnableSchema } from './server/trpc/routers/offerPosition/logisticsTour'
import type { invoicableOffersWherePresets } from './server/trpc/routers/invoice/invoicableOfferWheres'
import type { offerPositionCreateFromIssuanceSchema } from './server/trpc/routers/offerPosition/issuance'
import type { createInternalReservationSchema } from './server/trpc/routers/internalReservation'
import type { releaseMachineryForFreelancerBase } from './server/trpc/routers/freelancerSales'
import type { releaseMachineryForPartnersBase } from './server/trpc/routers/partnerSales'
import type { storageLoadCarrierCreateSchema, storageLoadCarrierUpdateSchema } from './server/trpc/routers/externalStorage/storageLoadCarrier'
import type { storageCategoryUpsertSchema } from './server/trpc/routers/externalStorage/storageCategory'
import type {
  breakDaySchema,
  calendarPageOfferCreationModeEmitSchema,
  createComment,
  createCreditNoteCustomPositionSchema,
  createCustomer,
  createInvoiceCustomPositionSchema,
  createInvoiceExtraDaysPositionSchema,
  createInvoiceSchema,
  createInvoiceToOfferPositionRelationSchema,
  createLogisticsTask,
  createMachinery,
  createMachineryTypeSchema,
  createOrUpdateOfferPositionSchema,
  createPositionTemplateSchema,
  createProformaInvoiceSchema,
  createQuickSaleSchema,
  createReceptionMachineryWithProps,
  customerLanguages,
  customerSchemaToCheckOrderAvailability,
  customerStatusSchema,
  customerTypes,
  defectType,
  generatedByAutomationSchema,
  groupedPhotoGroupSchema,
  internalCostPositionType,
  invoiceStatus,
  invoiceType,
  invoiceViewSchema,
  logisticsConditionRatingSchema,
  logisticsTaskAToBStatusSchema,
  logisticsTaskRoundtripFullStatusSchema,
  logisticsTaskRoundtripStatusSchema,
  logisticsTaskTypeSchema,
  logisticsTourDirectionsSchema,
  logisticsViewSchema,
  logisticsVisualDetailsPollutionsSchema,
  machineryAccessoryStatusWithSold,
  machineryAccessoryTrailerTypes,
  machineryCategories,
  machineryDrives,
  machineryFems,
  machineryGears,
  machineryInsuranceDeductible,
  machineryPurchasing,
  machineryRubrics,
  machineryStatusSchema,
  machineryStatusWithSold,
  manuallyCreatableLogisticsTypes,
  marketingMediaUpdateSchema,
  marketingViewSchema,
  modelChangesType,
  offerCreateSchema,
  offerModelChangeSchema,
  offerPositionGroupTypeSchema,
  offerPositionMachineryAccessoryCategoryFilterSchema,
  offerPositionPaymentStatus,
  offerPositionTypeSchema,
  offerPositionsToShipSchema,
  offerStatusSchema,
  offerTypeSchema,
  offerUpdateSchema,
  offerViewSchema,
  paymentConditions,
  positionTemplateBundleCreateSchema,
  positionTemplateBundleUpdateSchema,
  positionTemplateTypes,
  positionUnits,
  serviceProjectTimeTrackingEntryStatusSchema,
  storageUserCreateOrUpdateSchema,
  taxRates,
  updateComment,
  updateCustomer,
  updateLogisticsTask,
  updateMachinery,
  updateMachineryOperationHours,
  updateMachineryTypeSchema,
  updateOrCreateMachineryAccessorySchema,
  updatePositionTemplateSchema,
  updateReceptionMachineryWithProps,
  upsertGroupedPhotoSchema,
  upsertLogisticsDetailsSchema,
  userOfferMembershipRoleSchema,
  userTaskCreateSchema,
} from '~/server/schemas'
import type { machineryAccessoryCategories, machineryAccessoryStatusSchema, updateMachineryAccessory } from '~/server/schemas/machineryAccessory'
import type { fileSchema } from '~/server/schemas/common'
import type { specialPermissionsSchema } from '~/server/schemas/machineryAndMachineryAccessoryCommon'

export type { RentalPricingDataType } from './server/trpc/routers/shop/rentalPricing'

export type RouterOutput = inferRouterOutputs<Router>
export type RouterInput = inferRouterInputs<Router>
export type ApiMachineryGetAll = RouterOutput['machinery']['getAll'][number]
export type ApiMachineryGetAllForOverview = RouterOutput['machinery']['getAllForOverview']['machinery'][number]
export type ApiMachineryGetById = Exclude<RouterOutput['machinery']['getById'], null>
export type ApiPublicMachineryGetById = Exclude<RouterOutput['machinery']['public']['getById'], null>
export type ApiMachineryTypeGetAll = RouterOutput['machineryType']['getAll'][number]
export type ApiMachineryDriveGetAll = RouterOutput['machineryDrive']['getAll'][number]
export type ApiSoldMachineryGetByCustomerId = RouterOutput['machinery']['getSoldMachineryByCustomerId'][number]

export type ApiReceptionMachineryGetById = Exclude<RouterOutput['receptionMachinery']['getByMachineryId'], null>
export type ApiReceptionMachinerySpecialEquipment = RouterOutput['receptionMachinery']['getSpecialEquipments'][number]

export type ApiMachineryAccessoryGetAll = RouterOutput['machineryAccessory']['getAll'][number]
export type ApiMachineryAccessoryGetAllForOverview = RouterOutput['machineryAccessory']['getAllForOverview']['accessories'][number]
export type ApiMachineryAccessoryGetById = Exclude<RouterOutput['machineryAccessory']['getById'], null>
export type ApiPublicMachineryAccessoryGetById = Exclude<RouterOutput['machineryAccessory']['public']['getById'], null>
export type ApiSoldMachineryAccessoryGetByCustomerId = RouterOutput['machineryAccessory']['getSoldMachineryAccessoriesByCustomerId'][number]

export type ApiMachineryAccessoryGetCompatibleAccessoriesForBundle = RouterOutput['machineryAccessory']['compatibility']['getCompatibleAccessoriesForBundle'][number]
export type ApiMachineryAccessoryBundleGetAll = RouterOutput['machineryAccessory']['bundle']['getAll'][number]

export type ApiMachineryAccessoryGetSelectedAccessoriesForOfferByIds = RouterOutput['machineryAccessory']['getSelectedMachineryAccessoriesForOfferByIds'][number]

export type ApiCustomerGetAll = RouterOutput['customer']['getAll'][number]
export type ApiCustomerGetById = NonNullable<RouterOutput['customer']['getById']>

export type ApiPositionTemplateGetAll = RouterOutput['positionTemplate']['getAll'][number]
export type ApiOfferPositionGetById = Exclude<RouterOutput['offerPosition']['getById'], null>
export type ApiOfferPositionGetAll = RouterOutput['offerPosition']['getAll'][number]
export type ApiOfferPositionGetToCreateInvoice = RouterOutput['invoice']['getToCreateInvoice'][number]
export type ApiOfferPositionGetByIdForStatusTimeline = Exclude<RouterOutput['offerPosition']['getByIdForStatusTimeline'], null>

export type ApiInputOfferPositionUpdateStatus = RouterInput['offerPosition']['updateStatus']

type UpdatePositionTemplateBundle = UpdateMeta<z.infer<typeof positionTemplateBundleUpdateSchema>>
type CreatePositionTemplateBundle = CreateMeta<z.infer<typeof positionTemplateBundleCreateSchema>>
export type UpdateOrCreatePositionTemplateBundle = UpdatePositionTemplateBundle | CreatePositionTemplateBundle

export type ApiPositionTemplateBundleGetAllForOverview = RouterOutput['positionTemplateBundle']['getAllForOverview'][number]
export type ApiPositionTemplateBundleGetAllForOffer = RouterOutput['positionTemplateBundle']['getAllForOffer'][number]

export type ApiOfferGetAll = RouterOutput['offer']['getAll']['offers'][number]
export type ApiOfferGetById = Exclude<RouterOutput['offer']['getById'], null>
export type ApiOffergetServiceProjectForExternalStakeholders = Exclude<RouterOutput['offer']['getServiceProjectForExternalStakeholders'], null>
export type ApiOfferGetByIdForStatusTimeline = Exclude<RouterOutput['offer']['getByIdForStatusTimeline'], null>

export type ApiInputOfferGetById = RouterInput['offer']['getById']

export type ApiModelChangesGetAll = RouterOutput['modelChanges']['getAll'][number]

export type ApiItemSetGetAll = RouterOutput['itemSet']['getAll']['itemSets'][number]
export type ApiItemSetGetAllWithOngoingOfferPositions = RouterOutput['itemSet']['getAllWithOngoingOfferPositions']['itemSets'][number]
export type ApiItemSetGetById = Exclude<RouterOutput['itemSet']['getById'], null>
export type ApiItemSetGetByIdWithOngoingOfferPositions = Exclude<RouterOutput['itemSet']['getByIdWithOngoingOfferPositions'], null>

export type ApiItemSetPublicGetById = Exclude<RouterOutput['itemSet']['public']['getById'], null>

export type ApiStorageLoadCarrierGetAll = RouterOutput['externalStorage']['storageLoadCarrier']['getAll']['data'][number]

export type OfferPositionGroupType = z.infer<typeof offerPositionGroupTypeSchema>
export type OfferPositionTypes = z.infer<typeof offerPositionTypeSchema>
export type OfferStatus = z.infer<typeof offerStatusSchema>
export type OfferType = z.infer<typeof offerTypeSchema>
export type OfferCreate = z.infer<typeof offerCreateSchema>
export type OfferUpdate = z.infer<typeof offerUpdateSchema>
export type OfferPositionCreateOrUpdate = z.infer<typeof createOrUpdateOfferPositionSchema>
export type OfferView = z.infer<typeof offerViewSchema>

export type ApiUsersGetAll = RouterOutput['user']['getAll']['users'][number]
export type ApiUsersGetDrivers = RouterOutput['user']['getDrivers'][number]
export type ApiUsersGetCustomerUsers = RouterOutput['user']['getCustomerUsers'][number]

export type ApiLogisticsTaskGetAll = RouterOutput['logisticsTask']['getAll'][number]
export type ApiLogisticsTaskGetAllForOverview = RouterOutput['logisticsTask']['getAllForOverview']['logisticsWithCorrectPositionsToShip'][number]
export type ApiLogisticsTaskGetById = Exclude<RouterOutput['logisticsTask']['getByIdOrThrow'], null>
export type ApiLogisticsTaskGetByIdPositionsToShip = ApiLogisticsTaskGetById['positionsToShip'][number]
export type ApiLogisticsTaskGetByIdForStatusTimeline = Exclude<RouterOutput['logisticsTask']['getByIdForStatusTimeline'], null>

export type ApiInputLogisticsUpdateStatus = RouterInput['logisticsTask']['updateStatus']

export type ApiLogisticsTaskGetByCuid = Exclude<RouterOutput['logisticsTask']['getByCuidOrThrow'], null>

export type LogisticsTaskGetByCuidPositionToShip = Exclude<ApiLogisticsTaskGetByCuid['positionsToShip'][number], null> | Exclude<ApiLogisticsTaskGetById['positionsToShip'][number], null>
export type ApiDeliveryVehicleGetAll = RouterOutput['deliveryVehicle']['getAll'][number]
export type ApiCommentGetAll = RouterOutput['comment']['getAll'][number]

export type ApiInvoiceGetAll = RouterOutput['invoice']['getAll'][number]
export type ApiInvoiceGetAllForOverview = RouterOutput['invoice']['getAllForOverview']['invoices'][number]
export type ApiInputInvoiceGetAllOffersForPreparation = RouterInput['invoice']['offersForPreparation']
export type ApiInvoiceGetAllOffersForPreparation = RouterOutput['invoice']['offersForPreparation']['offers'][number]
export type ApiInputInvoiceGetAllOfferForInvoiceById = Exclude<RouterOutput['invoice']['offerForInvoiceById'], null>
export type ApiInvoiceGetById = Exclude<RouterOutput['invoice']['getById'], null>

export type ApiShoppingGetCompatibleAccessories = RouterOutput['shopping']['accessoryAvailabilities']['getCompatibleAccessories'][number]
export type ApiShoppingGetAllAccessories = RouterOutput['shopping']['accessoryAvailabilities']['allAccessories']['accessories'][number]

export type ApiInternalCostPositionGetAll = RouterOutput['internalCostPosition']['getAll'][number]

export type ApiDefectGetAll = RouterOutput['defect']['getAll'][number]

export type ApiTaskGetAllInput = RouterInput['userTask']['getTasks']

export type TaskCreate = z.infer<typeof userTaskCreateSchema>

export type ApiTaskGetAllAsCalendarEvents = RouterOutput['userTask']['getTasksAsCalendarEvents'][number]
export type ApiTaskGetAllServiceProjectsAsCalendarEvents = RouterOutput['userTask']['getOfferServiceProjectsAsCalendarEvents'][number]
export type CalendarEvent = ApiTaskGetAllAsCalendarEvents | ApiTaskGetAllServiceProjectsAsCalendarEvents

export type MachineryStatusWithSold = z.infer<typeof machineryStatusWithSold>
export type MachineryStatus = z.infer<typeof machineryStatusSchema>
export type MachineryCategories = z.infer<typeof machineryCategories>
export type MachineryDrives = z.infer<typeof machineryDrives>
export type MachineryRubrics = z.infer<typeof machineryRubrics>
export type MachineryPurchasing = z.infer<typeof machineryPurchasing>
export type MachineryInsuranceDeductible = z.infer<typeof machineryInsuranceDeductible>
export interface MachineryAggregatedPricingDecisionData {
  pricePurchaseEuros?: number
  operatingHours?: number
  internalCostPositionsPriceSum?: number
  totalMachineryRevenue?: number
}

export interface MachineryPartnerReleasePricingData {
  endCustomerSellingPrice?: number
  dealerSellingPrice?: number
}

export type CustomerStatus = z.infer<typeof customerStatusSchema>
export type CustomerTypes = z.infer<typeof customerTypes>
export type PaymentConditions = z.infer<typeof paymentConditions>
export type TaxRates = z.infer<typeof taxRates>

export type MachineryAccessoryStatusWithSold = z.infer<typeof machineryAccessoryStatusWithSold>
export type MachineryAccessoryStatus = z.infer<typeof machineryAccessoryStatusSchema>
export type MachineryAccessoryTrailerTypes = z.infer<typeof machineryAccessoryTrailerTypes>

export type SpecialPermissions = z.infer<typeof specialPermissionsSchema>

export type CRUDModes = 'update' | 'create'
interface UpdateMeta<UpdateSchema extends z.infer<z.ZodTypeAny>> {
  mode: Extract<CRUDModes, 'update'>
  data: UpdateSchema
}

interface CreateMeta<CreateSchema extends z.infer<z.ZodTypeAny>> {
  mode: Extract<CRUDModes, 'create'>
  data: CreateSchema
}

export type UpdateMachineryData = z.infer<typeof updateMachinery>
type UpdateMachinery = UpdateMeta<UpdateMachineryData>
type CreateMachinery = CreateMeta<z.infer<typeof createMachinery>>
export type UpdateOrCreateMachinery = UpdateMachinery | CreateMachinery

export type UpdateMachineryOperationHours = z.infer<typeof updateMachineryOperationHours>
export type UpdateReceptionMachinery = UpdateMeta<z.infer<typeof updateReceptionMachineryWithProps>>
type CreateReceptionMachinery = CreateMeta<z.infer<typeof createReceptionMachineryWithProps>>
export type UpdateOrCreateReceptionMachinery = UpdateReceptionMachinery | CreateReceptionMachinery

export type UpdateMachineryAccessoryData = z.infer<typeof updateMachineryAccessory>
export type UpdateMachineryAccessory = UpdateMeta<UpdateMachineryAccessoryData>
export type UpdateOrCreateMachineryAccessory = z.infer<typeof updateOrCreateMachineryAccessorySchema>

export type MachineryAccessoryCategoryFilter = z.infer<typeof offerPositionMachineryAccessoryCategoryFilterSchema>
export type MachineryAccessoryCategory = typeof machineryAccessoryCategories[number]

export type MarketingView = z.infer<typeof marketingViewSchema>

export type UpdateMachineryType = UpdateMeta<z.infer<typeof updateMachineryTypeSchema>>
type CreateMachineryType = CreateMeta<z.infer<typeof createMachineryTypeSchema>>
export type UpdateOrCreateMachineryType = UpdateMachineryType | CreateMachineryType

export type FileSchema = z.infer<typeof fileSchema>

export type UploadStatus = 'upload' | 'success' | 'error'
export interface UploadedFile {
  filekey: string
  status: UploadStatus
  hasPreview: boolean
  progress?: number
}

export type ShoppingMachineryAccessory = Exclude<Exclude<RouterOutput['shopping']['accessoryAvailabilities']['byMachineryId']['resultsByAccessoryCategory']['abusBogie'], undefined>['compatible'], number>[number]
interface ShoppingCartPositionAccessoryCategory {
  type: 'machineryAccessoryCategory'
  machineryAccessoryCategory: MachineryAccessoryCategory
  filters: MachineryAccessoryCategoryFilter
  isForkReplaceRequired?: boolean | null
  compatibleMachineryAccessoryId?: string
}
export interface ShoppingCartPositionAccessory {
  type: 'machineryAccessory'
  machineryAccessoryId: string
  lengthInMillimetersForFork?: number | null
  isForkReplaceRequired?: boolean | null
  compatibleMachineryAccessoryId?: string
}
export type ShoppingCartPosition = ShoppingCartPositionAccessory | ShoppingCartPositionAccessoryCategory

export type ApiCalendarMachinery = RouterOutput['calendar']['getMachinery']['machinery'][number]
export type ApiCalendarMachineryAccessory = RouterOutput['calendar']['getMachineryAccessory']['accessories'][number]
export interface CalendarPageOfferCreationModeProps {
  offerId?: string
  disableOfferTypeSwitch: boolean
  isOfferCreationMode: boolean
  selectedStartTime?: Date
  rentalDuration: number
  offerType: OfferType
  offerStatus?: OfferStatus
  isOfferEditMode?: boolean
  alreadyCheckedMachineryKeys: DataTableRowKey[]
  alreadyCheckedItemSetKeys: DataTableRowKey[]
  alreadyPlannedPositionKeys: DataTableRowKey[]
  shoppingCartPositions: ShoppingCartPosition[]
  isAccessoryOnlyOffer: boolean
  isAccessoryOnlyOfferDisabled?: boolean
  originalOfferId?: string
  deliveryAt?: Date | null
  groupInOffer?: number
  groupMode?: CRUDModes
  disabledMachineryIds?: string[]
  disabledItemSetIds?: string[]
  disabledAccessoryIds?: string[]
}
export type CalendarPageOfferCreationModeEmit = z.infer<typeof calendarPageOfferCreationModeEmitSchema>
export type ApiCalendarItemSet = RouterOutput['calendar']['getItemSet']['itemSets'][number]

export type CustomerLanguages = z.infer<typeof customerLanguages>

export type UpdateCustomer = UpdateMeta<z.infer<typeof updateCustomer>>
type CreateCustomer = CreateMeta<z.infer<typeof createCustomer>>
export type CreateCustomerData = CreateCustomer['data']
export type UpdateOrCreateCustomer = UpdateCustomer | CreateCustomer

export type PositionTemplateType = z.infer<typeof positionTemplateTypes>

export type PositionUnit = z.infer<typeof positionUnits>

export type UpdatePosition = UpdateMeta<z.infer<typeof updatePositionTemplateSchema>>
export type CreatePosition = CreateMeta<z.infer<typeof createPositionTemplateSchema>>
export type UpdateOrCreatePosition = UpdatePosition | CreatePosition

export type RentalDayCreate = Omit<RentalDay, 'offerId' | 'createdAt' | 'updatedByEmail'>
export type BreakDayCreate = z.infer<typeof breakDaySchema>

export type MachineryGearOptions = z.infer<typeof machineryGears>

export type LogisticsView = z.infer<typeof logisticsViewSchema>
export type LogisticsTaskAToBStatus = z.infer<typeof logisticsTaskAToBStatusSchema>
export type LogisticsTaskRoundtripStatus = z.infer<typeof logisticsTaskRoundtripStatusSchema>
export type LogisticsRentalFullStatus = z.infer<typeof logisticsTaskRoundtripFullStatusSchema>
export type LogisticsTaskType = z.infer<typeof logisticsTaskTypeSchema>
export type LogisticsTourDirections = z.infer<typeof logisticsTourDirectionsSchema>

export type CustomerSchemaToCheckOrderAvailability = z.infer<typeof customerSchemaToCheckOrderAvailability>

export type DispatcherTaskForApp = Awaited<ReturnType<typeof getTasksWithDetailsForDispatcher>>[number]

export enum DeliveryVehicleStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type UpsertLogisticsDetails = z.infer<typeof upsertLogisticsDetailsSchema>

export type LogisticsVisualDetailsPollution = z.infer<typeof logisticsVisualDetailsPollutionsSchema>
export type LogisticsConditionRating = z.infer<typeof logisticsConditionRatingSchema>

export type LogisticPlanningDayRowValue = 'noDeliveryDate' | number

type CreateComment = CreateMeta<z.infer<typeof createComment>>
export type UpdateComment = UpdateMeta<z.infer<typeof updateComment>>
export type UpdateOrCreateComment = CreateComment | UpdateComment

export type OfferPositionsToShip = z.infer<typeof offerPositionsToShipSchema>

export type InvoiceStatus = z.infer<typeof invoiceStatus>
export type InvoiceType = z.infer<typeof invoiceType>
export type InvoiceView = z.infer<typeof invoiceViewSchema>

export type CreateCreditNoteCustomPosition = z.infer<typeof createCreditNoteCustomPositionSchema>
export type CreateInvoice = z.infer<typeof createInvoiceSchema>
export type CreateInvoiceToOfferPositionRelation = z.infer<typeof createInvoiceToOfferPositionRelationSchema>
export type CreateProformaInvoice = z.infer<typeof createProformaInvoiceSchema>

export type CreateInvoiceCustomPosition = z.infer<typeof createInvoiceCustomPositionSchema>
export type CreateInvoiceExtraDaysPosition = z.infer<typeof createInvoiceExtraDaysPositionSchema>

export type CreateInvoiceCustomOrCreditNotePosition = CreateCreditNoteCustomPosition | CreateInvoiceCustomPosition
export type CreateInvoiceCustomOrCreditNotePositionType = CreateInvoiceCustomOrCreditNotePosition['type']

export type OfferPositionPaymentStatus = z.infer<typeof offerPositionPaymentStatus>

export type MachineryFem = z.infer<typeof machineryFems>

export type InvoiceOfferPositionRow = ApiInvoiceGetById['offerPositions'][number] & {
  children?: InvoiceOfferPositionRow[]
}

type CreateInternalCostPosition = CreateMeta<z.infer<typeof createInternalCostPosition>>
export type UpdateInternalCostPosition = UpdateMeta<z.infer<typeof updateInternalCostPosition>>
export type UpdateOrCreateInternalCostPosition = CreateInternalCostPosition | UpdateInternalCostPosition

export type InternalCostPositionType = z.infer<typeof internalCostPositionType>

export type GeneratedByAutomation = z.infer<typeof generatedByAutomationSchema>

export type UpdateDefect = UpdateMeta<z.infer<typeof updateDefectSchema>>
export type CreateDefect = CreateMeta<z.infer<typeof createDefectSchema>>
export type UpdateOrCreateDefect = UpdateDefect | CreateDefect
export type DefectType = z.infer<typeof defectType>
export type ModelChangesType = z.infer<typeof modelChangesType>

type UpdateItemSet = UpdateMeta<z.infer<typeof updateItemSetSchema>>
type CreateItemSet = CreateMeta<z.infer<typeof createItemSetSchema>>
export type UpdateOrCreateItemSet = UpdateItemSet | CreateItemSet

type UpdateSpecialPositions = UpdateMeta<z.infer<typeof updateSpecialPositionsSchema>>
type CreateSpecialPositions = CreateMeta<z.infer<typeof createSpecialPositionsSchema>>
export type UpdateOrCreateSpecialPositions = UpdateSpecialPositions | CreateSpecialPositions

export type TerminateRentalPositions = z.infer<typeof terminateRentalPositionsSchema>
export type UpdateOfferPositionNotReturnable = z.infer<typeof updateNotReturnableSchema>

export type UpdateLogisticsTask = z.infer<typeof updateLogisticsTask>
export type CreateLogisticsTask = z.infer<typeof createLogisticsTask>

export type InvoicableOffersWherePreset = keyof typeof invoicableOffersWherePresets

/**
 * TODO: remove this function when we don't need it anymore
 * @temporary
 * This is for allow user to edit the termination day of the offers
 * that are terminated before the partial termination implemented
 * see https://github.com/sidestream-tech/hanselmann-os/issues/1526 for more information
 */
export type ApiInputOfferRevertEndedObligation = RouterInput['offer']['revertEndedOfferObligation']

export type UserOfferMembershipRole = z.infer<typeof userOfferMembershipRoleSchema>
export type ApiUserOfferMembershipGetAll = RouterOutput['userOfferMembership']['getAllMembershipsForOffer'][number]

export interface MachineryAccessoryOverviewSelectOptions {
  selectedKeys: string[]
  setId?: string
  disableWhenMachineryAccessoryIsInOngoingOffer?: boolean
}

export type ManuallyCreatableLogisticsType = z.infer<typeof manuallyCreatableLogisticsTypes>

export type CalendarHandlerConfig = { groupMode: 'create', groupInOffer?: null } | { groupMode: 'update', groupInOffer: number }
export type OfferPositionCreateForLogisticsTask = z.infer<typeof offerPositionCreateFromIssuanceSchema>

export type SalePDFType = RouterInput['offer']['uploadSalePdf']['pdfType']

export type OfferModelChange = z.infer<typeof offerModelChangeSchema>

export type CreateQuickSale = z.infer<typeof createQuickSaleSchema>
export type ApiQuickSaleGetAllByMachineryId = RouterOutput['quickSale']['getAllByMachineryId'][number]
export type ApiQuickSaleGetAllByPartnerSales = RouterOutput['quickSale']['getAllByPartnerSales'][number]

export type FreelancerData = RouterOutput['freelancerSales']['getFreelancers'][number]
export type FreelancerSalesGetMachineryReleasedForFreelancerConditionsInput = RouterInput['freelancerSales']['getMachineryReleasedForFreelancerConditions']

export type PartnerSalesGetMachineryReleasedForPartnerConditionsInput = RouterInput['partnerSales']['getMachineryReleasedForPartnerConditions']

export type CreateInternalReservation = z.infer<typeof createInternalReservationSchema>

export type ApiInternalReservationGetAll = RouterOutput['internalReservation']['getAll'][number]
export type ApiFreelancerSalesGetMachineryReleasedForFreelancerConditions = RouterOutput['freelancerSales']['getMachineryReleasedForFreelancerConditions'][number]
export type FreelancerSalesReleaseMachineryForFreelancerBase = z.infer<typeof releaseMachineryForFreelancerBase>
export type ApiPartnerSalesGetMachineryReleasedForPartnerConditions = RouterOutput['partnerSales']['getMachineryReleasedForPartnerConditions'][number]
export type PartnerSalesReleaseMachineryForPartnerBase = z.infer<typeof releaseMachineryForPartnersBase>

export type ApiReceptionMachineryHistoryGetAllForOverview = RouterOutput['receptionMachineryHistory']['getAllForOverview'][number]

type UpdateStorageLoadCarrier = UpdateMeta<z.infer<typeof storageLoadCarrierUpdateSchema>>
type CreateStorageLoadCarrier = CreateMeta<z.infer<typeof storageLoadCarrierCreateSchema >>
export type UpdateOrCreateStorageLoadCarrier = UpdateStorageLoadCarrier | CreateStorageLoadCarrier

type UpdateStorageUser = UpdateMeta<z.infer<typeof storageUserCreateOrUpdateSchema>>
type CreateStorageUser = CreateMeta<z.infer<typeof storageUserCreateOrUpdateSchema >>
export type UpdateOrCreateStorageUser = UpdateStorageUser | CreateStorageUser

export type ApiStorageUserGetAll = RouterOutput['externalStorage']['storageUser']['getAll'][number]

export type StorageCategoryUpsert = z.infer<typeof storageCategoryUpsertSchema>

type RouterOutputExternalStoragePosition = RouterOutput['externalStorage']['storagePosition']
export type ApiStoragePositionGetAll = RouterOutputExternalStoragePosition['getAll']['data'][number]
export type ApiStoragePositionById = Exclude<RouterOutputExternalStoragePosition['getById'], null>
export type SplitStoragePosition = ApiStoragePositionById['splitStoragePositions'][number]
export type ApiStoragePositionHistoryEntry = RouterOutputExternalStoragePosition['getHistoryById'][number]

export type ApiStoragePositionSetHistoryEntry = RouterOutput['externalStorage']['storagePositionSet']['getHistoryById'][number]
export type ApiUpsertedStoragePositionSet = RouterOutput['externalStorage']['storagePositionSet']['upsert']
export type { StoragePositionSetUpsert, StoragePositionSetOffload, StoragePositionSetStore, StoragePositionUpsert } from './server/trpc/routers/externalStorage/storagePositionSet'
export type ApiStoragePositionSetById = NonNullable<RouterOutput['externalStorage']['storagePositionSet']['getById']>
export type ApiStoragePositionSetGetAll = RouterOutput['externalStorage']['storagePositionSet']['getAll'][number]
export type ApiStoragePositionSetOverviewByCustomerId = RouterOutput['externalStorage']['storagePositionSet']['getOverviewByCustomerId']

export type ApiStorageProductGetAll = RouterOutput['externalStorage']['storageProduct']['getAll']['data'][number]
export type ApiStorageProductById = Exclude<RouterOutput['externalStorage']['storageProduct']['getById'], null>

export type ApiInboundLogisticsTaskGetAll = RouterOutput['externalStorage']['inboundLogisticsTask']['getAll']['data'][number]
export type ApiInboundLogisticsTaskById = NonNullable<RouterOutput['externalStorage']['inboundLogisticsTask']['getById']>
export type ApiInboundLogisticsTaskForDriver = NonNullable<RouterOutput['externalStorage']['inboundLogisticsTask']['driver']['getById']>
export type ApiInboundLogisticsTaskGetAllForDriver = RouterOutput['externalStorage']['inboundLogisticsTask']['driver']['getAll'][number]

export type ApiStorageLoadCarrierById = NonNullable<RouterOutput['externalStorage']['storageLoadCarrier']['getById']>

export type ApiStorageProjectGetAll = RouterOutput['externalStorage']['storageProject']['getAll']['data'][number]
export type ApiStorageProjectMinimal = RouterOutput['externalStorage']['storageProject']['getAllByCustomer'][number]
export type { StorageProjectUpsert } from './server/trpc/routers/externalStorage/storageProject'
export type { PartialStoragePositionUpsert, PartialStoragePositionLoad } from './server/trpc/routers/externalStorage/partialStoragePosition'

export enum PartialStoragePositionStatus {
  RequestedForRetrieval = 'requestedForRetrieval',
  ReadyForRetrieval = 'readyForRetrieval',
  RetrievalScheduled = 'retrievalScheduled',
  Loaded = 'loaded',
  Delivered = 'delivered',
}

export enum OutboundLogisticsTaskStatus {
  Created = 'created',
  RequestedForRetrieval = 'requestedForRetrieval',
  Loaded = 'loaded',
  Done = 'done',
}

export enum StoragePositionOffloadedWith {
  Forklift = 'forklift',
  OverheadCrane = 'overheadCrane',
  PickAndCarryCrane = 'pickAndCarryCrane',
  Other = 'other',
}

export enum InboundLogisticsTaskStatus {
  Created = 'created',
  RequestedForStorage = 'requestedForStorage',
  Storing = 'storing',
  Done = 'done',
}

export type ApiPartialStoragePosition = RouterOutput['externalStorage']['partialStoragePosition']['getAll']['data'][number]
export type ApiOutboundLogisticsTask = RouterOutput['externalStorage']['outboundLogisticsTask']['getAll']['data'][number]
export type ApiOutboundLogisticsTaskById = NonNullable<RouterOutput['externalStorage']['outboundLogisticsTask']['getById']>
export type ApiOutboundLogisticsTaskForDriver = NonNullable<RouterOutput['externalStorage']['outboundLogisticsTask']['driver']['getById']>
export type ApiOutboundLogisticsTaskGetAllForDriver = RouterOutput['externalStorage']['outboundLogisticsTask']['driver']['getAll'][number]

export type { OutboundLogisticsTaskUpsert, UpsertableOutboundLogisticsTaskStatus } from './server/trpc/routers/externalStorage/outboundLogisticsTask'

export type MarketingMediaUpdate = z.infer<typeof marketingMediaUpdateSchema>

export type ServiceProjectTimeTrackingEntryStatus = z.infer<typeof serviceProjectTimeTrackingEntryStatusSchema>
export type ApiServiceProjectTimeTrackingEntryGetAllByOfferId = RouterOutput['serviceProjectTimeTrackingEntry']['getAllByOfferId'][number]

export type ApiServiceProjectDailyReportGetAllByOfferId = RouterOutput['serviceProjectDailyReport']['getAllByOfferId'][number]
export type ApiServiceProjectInterimReportGetAllByOfferId = RouterOutput['serviceProjectInterimReport']['getAllByOfferId'][number]

export interface FilePath {
  path: string
}

export type { StoragePositionForDiffing, StoragePositionSetForDiffing } from './server/trpc/routers/externalStorage/storagePositionHistory'

export type ApiOtherInvoiceRecipientAll = RouterOutput['otherInvoiceRecipient']['getAll'][number]
export type { OtherInvoiceRecipientUpsert } from '~/server/trpc/routers/otherInvoiceRecipient'

export enum StandardWorkingDays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 0,
}

export enum ServiceProjectTravelCancellationReason {
  HotelStay = 'hotelStay',
  InternalProject = 'internalProject',
}

export type ApiOutboundLogisticsTaskDeliveryGetAll = RouterOutput['externalStorage']['outboundLogisticsTaskDelivery']['getAll'][number]
export type OutboundLogisticsTaskDeliveryUpdateDeliveryProofPdf = RouterInput['externalStorage']['outboundLogisticsTaskDelivery']['updateDeliveryProofPdf']

export type ApiRentalPauseGetAll = RouterOutput['rentalPause']['getAllByOfferId'][number]
export type RentalPauseUpsert = RouterInput['rentalPause']['upsert']
export type RentalPauseDelete = RouterInput['rentalPause']['delete']

export type ApiExternalStorageInvoicingEvent = RouterOutput['externalStorage']['invoicingEvent']['getAllByStoragePosition']['events'][number]

export type ApiGroupedPhotoByGroup = RouterOutput['groupedPhoto']['byGroup'][number]
export type UpsertGroupedPhoto = z.infer<typeof upsertGroupedPhotoSchema>
export type GroupedPhotoGroup = z.infer<typeof groupedPhotoGroupSchema>

export interface Pagination {
  skip: number
  take: number
}

export type ApiExternalSalesMarketplaceGetAll = RouterOutput['externalSalesMarketplace']['getAll'][number]
export type ApiExternalSalesMarketplaceUpsertInput = RouterInput['externalSalesMarketplace']['upsert']

export type ApiRentalPricesOfMachinery = RouterOutput['shop']['rentalPricing']['getPricesOfMachinery']
export type ApiRentalPricesOfMachineryType = RouterOutput['shop']['rentalPricing']['getPricesOfMachineryType']

export type ApiShopMachineryAccessorySaleGetAll = RouterOutput['shop']['machineryAccessory']['getAllReleasedForSale']['data'][number]
