<template>
  <div class="cookie-control">
    <CookieControl v-bind="$attrs" />
  </div>
</template>

<style scoped>
.cookie-control {
    :deep(.cookieControl__ModalInputWrapper) button {
        padding-inline: 0px;
    }

    :deep(.cookieControl__ModalContent),
    :deep(.cookieControl__BarButtons) button,
    :deep(.cookieControl__ModalButtons) button,
    :deep(button.cookieControl__ModalClose) {
        border-radius: 3px;
    }

    :deep(.cookieControl__Bar) {
        box-shadow: 0 0 5px 5px rgba(0,0,0,0.3);
    }
}
</style>
