<script setup lang="ts">
import { differenceInDays, isAfter, isSameDay, isWithinInterval, startOfDay } from 'date-fns'
import type { DataTableColumn, DataTableColumns, DataTableRowKey } from 'naive-ui'
import { NButton } from 'naive-ui'
import type { VNode } from 'vue'
import { useRouteQuery } from '@vueuse/router'
import { z } from 'zod'
import Cell from './Cell.vue'
import type { ApiCalendarMachinery, ApiCalendarMachineryAccessory, ApiMachineryGetById, CRUDModes, CalendarPageOfferCreationModeEmit, CalendarPageOfferCreationModeProps, MachineryAccessoryCategory, MachineryDrives, MachineryRubrics, OfferType, ShoppingCartPosition, ShoppingCartPositionAccessory } from '~/types'
import CalendarPopoverMachinery from '~/components/Calendar/PopoverMachinery.vue'
import CalendarPopoverMachineryAccessory from '~/components/Calendar/PopoverMachineryAccessory.vue'
import CalendarPopoverRepairOrReleaseMachinery from '~/components/Calendar/PopoverRepairOrReleaseMachinery.vue'
import CalendarPopoverRepairMachineryAccessory from '~/components/Calendar/PopoverRepairMachineryAccessory.vue'
import CalendarPopoverOffer from '~/components/Calendar/PopoverOffer.vue'
import CalendarPopoverPositionLocation from '~/components/Calendar/PopoverPositionLocation.vue'
import CalendarPopoverNotAvailable from '~/components/Calendar/PopoverNotAvailable.vue'
import CalendarPopoverReservation from '~/components/Calendar/PopoverReservation.vue'
import { machineryFems, machineryRubrics, offerTypeSchema } from '~/server/schemas'
import { machineryAccessoryCategorySelectOptions, machineryAccessoryCategoryToGerman, machineryDrivesToGerman, offerTypesToGerman } from '~/translations'

const props = withDefaults(defineProps<{
  offerCreationMode?: CalendarPageOfferCreationModeProps
  allowOfferCreationMode?: boolean
  additionalFiltersMachinery?: Record<string, any>
  additionalFiltersMachineryAccessory?: Record<string, any>
  additionalOfferFilters?: Record<string, any>
  showMachineryFilters?: boolean
  showMachineryAccessoryFilters?: boolean
  showCalendarFor?: 'machinery' | 'machineryAccessories' | 'all'
  shouldOnlyIncludeItemsWithOffers?: boolean
  startTime?: Date
  daysToRender?: number
  showSettings?: boolean
}>(), {
  allowOfferCreationMode: true,
  offerCreationMode: () => ({
    offerId: undefined,
    selectedStartTime: undefined,
    disableOfferTypeSwitch: false,
    isOfferCreationMode: false,
    rentalDuration: 1,
    offerType: 'rental',
    alreadyCheckedMachineryKeys: [],
    alreadyCheckedItemSetKeys: [],
    alreadyPlannedPositionKeys: [],
    shoppingCartPositions: [],
    isAccessoryOnlyOffer: false,
    originalOfferId: undefined,
    offerStatus: 'offer',
  }),
  additionalFiltersMachinery: () => ({}),
  additionalFiltersMachineryAccessory: () => ({}),
  additionalOfferFilters: () => ({}),
  showMachineryFilters: true,
  showMachineryAccessoryFilters: true,
  showCalendarFor: 'all',
  shouldOnlyIncludeItemsWithOffers: false,
  startTime: undefined,
  daysToRender: undefined,
  showSettings: true,
})
const emit = defineEmits<{ (e: 'addToOffer', selectedItems: CalendarPageOfferCreationModeEmit, offerType?: OfferType, groupInOffer?: number, groupMode?: CRUDModes): void }>()
const DAYS_TO_RENDER = 40
const CELL_WIDTH_PX = 90

const { canAccessPage } = useAuthorization()

const today = startOfDay(new Date())
const defaultStartTimeDate = props.startTime ?? props.offerCreationMode.selectedStartTime
const defaultStartTime = defaultStartTimeDate ? startOfDay(defaultStartTimeDate) : addDaysDstSafe(today, -3)
const selectedStartTime = useRouteQuery('calendarSelectedTime', String(defaultStartTime.getTime()), { transform: Number })
const daysToRender = props.daysToRender ?? DAYS_TO_RENDER
const calculatedEndDate = computed(() => addDaysDstSafe(selectedStartTime.value, daysToRender - 1))
const checkedMachineryKeys = ref<DataTableRowKey[]>(props.offerCreationMode.alreadyCheckedMachineryKeys)
const checkedMachineryKey = computed(() => checkedMachineryKeys.value[0] as string | undefined)

const disabledMachineryIds = computed(() => props.offerCreationMode.disabledMachineryIds ?? [])
const disabledAccessoryIds = computed(() => props.offerCreationMode.disabledAccessoryIds ?? [])

const checkedItemSetKeys = ref<DataTableRowKey[]>(props.offerCreationMode.alreadyCheckedItemSetKeys)

const { machineryType: queryMachineryType, machineryDrive: queryMachineryDrives, machineryAccessory: queryMachineryAccessory, machinery: queryMachinery, itemSet: queryItemSet } = useQuery()

const { data: machineryTypes } = queryMachineryType.all()
const { data: machineryDrives } = queryMachineryDrives.all()

const { data: selectedMachineryToAdd } = queryMachinery.byId(checkedMachineryKey, { receptionMachinery: true }, true)

const selectedItemSetWhere = computed(() => {
  if (checkedItemSetKeys.value.length === 0) {
    return null
  }

  return {
    id: { in: checkedItemSetKeys.value },
  }
})

const { data: selectedItemSetsToAddData } = queryItemSet.all(selectedItemSetWhere)
const selectedItemSetsToAdd = computed(() => selectedItemSetsToAddData.value?.itemSets ?? [])

const { openOfferPage } = useGlobalOpeners()
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const createFromOtherOffer = useMutation({
  mutationFn: $trpc.offer.createFromOtherOffer.mutate,
  onError: makeTrpcErrorToast(notification, { description: `${props.offerCreationMode.offerType === 'sale' ? 'Der Verkauf' : 'Die Vermietung'} konnte nicht erstellt werden` }),
  onSuccess: async (offer) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
    ])
    notification.success({ title: `${offer?.type === 'sale' ? 'Der Verkauf' : 'Die Vermietung'} wurde erfolgreich erstellt`, duration: 4500 })
    if (offer) {
      openOfferPage({ mode: 'edit', id: offer.id, isCustomerEditingEnabled: true })
    }
  },
})

// Unset route query when navigating away to not bleed into other pages
onUnmounted(() => {
  /**
   * Unsets query by setting it to `undefined`:
   * - This functionality was added to vueuse here: https://github.com/vueuse/vueuse/issues/3050
   * - Sadly the types went out of sync, so we have to use this utility which types the param as `<undefined>` manually to satisfy TS: https://github.com/vueuse/vueuse/issues/3294
   */
  useRouteQuery<undefined>('calendarSelectedTime').value = undefined
})

const isSearchingIncludesOffers = ref(false)

const visibleTypes = ref<OfferType[]>(['rental', 'sale', 'service-project', 'special'])
const visibleTypesOptions = offerTypeSchema.options.map(type => ({ label: offerTypesToGerman[type], value: type }))
const creationTypesOptions = offerTypeSchema.options.map(type => ({ label: offerTypesToGerman[type], value: type, disabled: !['rental', 'sale'].includes(type) }))

const isOfferCreationMode = useRouteQuery('isOfferCreationMode', String(props.offerCreationMode.isOfferCreationMode), {
  transform: (val) => {
    const parsed = z.enum(['true', 'false']).safeParse(String(val))
    if (parsed.success) {
      return parsed.data === 'true'
    }
    return false
  },
})

const rentalDuration = ref(props.offerCreationMode.rentalDuration)
const offerType = ref<OfferType>(props.offerCreationMode.offerType)
const isAccessoryOnlyOffer = ref(props.offerCreationMode.isAccessoryOnlyOffer)
const rentalEndDate = computed(() => offerType.value === 'sale' ? undefined : addDaysDstSafe(selectedStartTime.value, rentalDuration.value - 1))
const deliveryAt = ref(props.offerCreationMode.deliveryAt?.getTime() ?? addDaysDstSafe(selectedStartTime.value, -1).getTime())

watch(isOfferCreationMode, (isEnabled) => {
  if (isEnabled) {
    visibleTypes.value = ['rental', 'sale']
    isSearchingIncludesOffers.value = false
  }
})

const rubricOptions = useTranslatedSelectOptions('machinery.rubric', machineryRubrics.options)

const machineryFieldsForFulltextSearch = [
  '/id',
  '/serialNumber',
  '/deliveryCompanyName',
  '/producerCompanyName',
  '/machineryRubric',
  '/type/name',
  { field: '/receptionMachinery/liftingHeightInMillimeters', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) },
  { field: '/receptionMachinery/liftingWeight', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) },
  { field: '/forkLengthInMillimeters', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) },
  { field: '/workHeightInMillimeters', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) },
  '/relatedOfferPositions/some/relatedOffer/deliveryLocation',
  '/relatedOfferPositions/some/relatedOffer/claimingPartnerName',
  '/relatedOfferPositions/some/relatedOffer/customer/name',
  '/relatedOfferPositions/some/relatedOffer/contactPerson/name',
]
const { fulltextSearchValue: machineryFulltextSearchValue, where: machineryFulltextSearchWhere } = useFilterData({ filters: machineryFieldsForFulltextSearch })

const machineryAccessoryFieldsForFulltextSearch = ['/id', '/producerCompanyName', '/comment', '/storageLocation', '/productCode', '/createdBy/name', '/typeName']
const { fulltextSearchValue: machineryAccessoryFulltextSearchValue, where: machineryAccessoryFulltextSearchWhere } = useFilterData({ filters: machineryAccessoryFieldsForFulltextSearch })

// Machinery Filters
const selectedRubric: Ref<MachineryRubrics | null> = ref(null)
const selectedMachineTypeId: Ref<string | null> = ref(null)
const selectedDriveId: Ref<string | null> = ref(null)
const minLiftingWeight: Ref<number | null> = ref(null)
const minLiftingHeight: Ref<number | null> = ref(null)
const maxWidthInMillimeters: Ref<number | null> = ref(null)
const maxHeightInMillimeters: Ref<number | null> = ref(null)
const maxWorkHeightInMillimeters: Ref<number | null> = ref(null)
const showMachineryLocationRow: Ref<boolean> = ref(false)
const minOperatingHours: Ref<number | null> = ref(null)
const maxOperatingHours: Ref<number | null> = ref(null)
// Machinery connected accessories filters
const selectedMachineAccessoryCategory: Ref<MachineryAccessoryCategory | null> = ref(null)
const machineAccessoryProducerCompanyName: Ref<string | null> = ref(null)
const minMachineAccessoryHeightInMillimeters: Ref<number | null> = ref(null)
const maxMachineAccessoryHeightInMillimeters: Ref<number | null> = ref(null)
const minMachineAccessoryLengthInMillimeters: Ref<number | null> = ref(null)
const maxMachineAccessoryLengthInMillimeters: Ref<number | null> = ref(null)
const minMachineAccessoryWidthInMillimeters: Ref<number | null> = ref(null)
const maxMachineAccessoryWidthInMillimeters: Ref<number | null> = ref(null)
const machineAccessoryVolt: Ref<string | null> = ref(null)
const minMachineAccessoryLiftingWeight: Ref<number | null> = ref(null)

function resetMachineAccessoryFilters() {
  machineAccessoryProducerCompanyName.value = null
  minMachineAccessoryHeightInMillimeters.value = null
  maxMachineAccessoryHeightInMillimeters.value = null
  minMachineAccessoryLengthInMillimeters.value = null
  maxMachineAccessoryLengthInMillimeters.value = null
  minMachineAccessoryWidthInMillimeters.value = null
  maxMachineAccessoryWidthInMillimeters.value = null
  machineAccessoryVolt.value = null
  minMachineAccessoryLiftingWeight.value = null
}

watch(selectedMachineAccessoryCategory, (newVal, oldVal) => oldVal && newVal !== oldVal && resetMachineAccessoryFilters())

function resetFilters() {
  selectedRubric.value = null
  selectedMachineTypeId.value = null
  selectedDriveId.value = null
  minLiftingWeight.value = null
  minLiftingHeight.value = null
  maxWidthInMillimeters.value = null
  maxHeightInMillimeters.value = null
  maxWorkHeightInMillimeters.value = null
  minOperatingHours.value = null
  maxOperatingHours.value = null
  resetMachineAccessoryFilters()

  selectedStartTime.value = defaultStartTime.getTime()
  visibleTypes.value = ['rental', 'sale']
}

const numericalFilters = computed(() => ({
  minLiftingWeight: minLiftingWeight.value ? (minLiftingWeight.value * 0.9) : undefined,
  minLiftingHeight: minLiftingHeight.value ? (minLiftingHeight.value * 0.9) : undefined,
  maxWidthInMillimeters: maxWidthInMillimeters.value ?? undefined,
  maxHeightInMillimeters: maxHeightInMillimeters.value ?? undefined,
  maxWorkHeightInMillimeters: maxWorkHeightInMillimeters.value ?? undefined,
}))

// Accessory Filters
const selectedMachineryAccessoryCategories: Ref<string[]> = ref([])
const fem: Ref<string[] | null> = ref(null)
const weightInKilograms: Ref<number | null> = ref(null)
const distanceInnerEdgeToInnerEdge: Ref<number | null> = ref(null)
const distanceOuterEdgeToOuterEdge: Ref<number | null> = ref(null)
const cubicMeters: Ref<number | null> = ref(null)
const retractionLugsWidth: Ref<number | null> = ref(null)
const retractionLugsHeight: Ref<number | null> = ref(null)
const retractionLugsDistanceInnerEdgeToInnerEdge: Ref<number | null> = ref(null)
const retractionLugsDistanceOuterEdgeToOuterEdge: Ref<number | null> = ref(null)
const drive: Ref<string | null> = ref(null)
const volt: Ref<string | null> = ref(null)
const wheelSize: Ref<string | null> = ref(null)
const comment: Ref<string | null> = ref(null)
const productCode: Ref<string | null> = ref(null)
const minAccessoryLiftingWeight: Ref<number | null> = ref(null)
const maxAccessoryLiftingWeight: Ref<number | null> = ref(null)
const minAccessoryLengthInMillimeters: Ref<number | null> = ref(null)
const maxAccessoryLengthInMillimeters: Ref<number | null> = ref(null)
const minAccessoryWidthInMillimeters: Ref<number | null> = ref(null)
const maxAccessoryWidthInMillimeters: Ref<number | null> = ref(null)
const minAccessoryHeightInMillimeters: Ref<number | null> = ref(null)
const maxAccessoryHeightInMillimeters: Ref<number | null> = ref(null)

function resetAccessoryFilters() {
  weightInKilograms.value = null
  distanceInnerEdgeToInnerEdge.value = null
  distanceOuterEdgeToOuterEdge.value = null
  cubicMeters.value = null
  retractionLugsWidth.value = null
  retractionLugsHeight.value = null
  retractionLugsDistanceInnerEdgeToInnerEdge.value = null
  retractionLugsDistanceOuterEdgeToOuterEdge.value = null
  drive.value = null
  volt.value = null
  wheelSize.value = null
  comment.value = null
  productCode.value = null
  selectedStartTime.value = defaultStartTime.getTime()
  selectedMachineryAccessoryCategories.value = []
  fem.value = null
  visibleTypes.value = ['rental', 'sale']
  minAccessoryLiftingWeight.value = null
  maxAccessoryLiftingWeight.value = null
  minAccessoryLengthInMillimeters.value = null
  maxAccessoryLengthInMillimeters.value = null
  minAccessoryWidthInMillimeters.value = null
  maxAccessoryWidthInMillimeters.value = null
  minAccessoryHeightInMillimeters.value = null
  maxAccessoryHeightInMillimeters.value = null
}

const accessoryNumericalFilters = computed(() => ({
  weightInKilograms: weightInKilograms.value ?? undefined,
  distanceInnerEdgeToInnerEdge: distanceInnerEdgeToInnerEdge.value ?? undefined,
  distanceOuterEdgeToOuterEdge: distanceOuterEdgeToOuterEdge.value ?? undefined,
  cubicMeters: cubicMeters.value ?? undefined,
  retractionLugsWidth: retractionLugsWidth.value ?? undefined,
  retractionLugsHeight: retractionLugsHeight.value ?? undefined,
  retractionLugsDistanceInnerEdgeToInnerEdge: retractionLugsDistanceInnerEdgeToInnerEdge.value ?? undefined,
  retractionLugsDistanceOuterEdgeToOuterEdge: retractionLugsDistanceOuterEdgeToOuterEdge.value ?? undefined,
  minLiftingWeight: minAccessoryLiftingWeight.value ?? undefined,
  maxLiftingWeight: maxAccessoryLiftingWeight.value ?? undefined,
  minLengthInMillimeters: minAccessoryLengthInMillimeters.value ?? undefined,
  maxLengthInMillimeters: maxAccessoryLengthInMillimeters.value ?? undefined,
  minWidthInMillimeters: minAccessoryWidthInMillimeters.value ?? undefined,
  maxWidthInMillimeters: maxAccessoryWidthInMillimeters.value ?? undefined,
  minHeightInMillimeters: minAccessoryHeightInMillimeters.value ?? undefined,
  maxHeightInMillimeters: maxAccessoryHeightInMillimeters.value ?? undefined,
}))

const { calendar: queryCalendar, customer: queryCustomer } = useQuery()

const machineryTypeOptions = computed(() => machineryTypes.value?.map(type => ({
  label: type.name,
  value: type.id,
})))

const machineryDrivesOptions = computed(() => machineryDrives?.value?.map(drive => ({
  label: machineryDrivesToGerman[drive.name as MachineryDrives],
  value: drive.id,
})))

const machineryAccessoryFEMSelectOptions = machineryFems.options.map(fem => ({ label: fem, value: fem }))

const machineryAttachedAccessoryCategoryOptions = computed(() => machineryAccessoryCategorySelectOptions.filter(o => ['fork', 'sideShifter', 'forkAdjuster', 'forkCarriage', 'charger'].includes(o.value)))

const machineryById = computed(() => {
  if (!machinery.value) {
    return {}
  }

  const preparedMachineryById: Record<string, typeof machinery.value[number] & { machineryRowCount: number }> = {}

  for (const m of machinery.value) {
    const machineryRowCount = (preparedMachineryById[m.id]?.machineryRowCount ?? 0) + 1
    preparedMachineryById[m.id] = { ...m, machineryRowCount }
  }

  return preparedMachineryById
})

const machineryWhere = computed(() => {
  const operatingHoursWhere = (maxOperatingHours.value || minOperatingHours.value)
    ? {
        receptionMachinery: {
          operatingHours: {
            ...generatePrismaGreaterThanOrEqualWhere(minOperatingHours.value),
            ...generatePrismaLessThanOrEqualWhere(maxOperatingHours.value),
          },
        },
      }
    : {}

  let attachedMachineryAccessoryWhere: { some: Record<string, any> } | undefined

  if (selectedMachineAccessoryCategory.value) {
    attachedMachineryAccessoryWhere = { some: { category: selectedMachineAccessoryCategory.value } }

    if (selectedMachineAccessoryCategory.value === 'fork') {
      attachedMachineryAccessoryWhere.some.heightInMillimeters = {
        ...generatePrismaGreaterThanOrEqualWhere(minMachineAccessoryHeightInMillimeters.value),
        ...generatePrismaLessThanOrEqualWhere(maxMachineAccessoryHeightInMillimeters.value),
      }
      attachedMachineryAccessoryWhere.some.lengthInMillimeters = {
        ...generatePrismaGreaterThanOrEqualWhere(minMachineAccessoryLengthInMillimeters.value),
        ...generatePrismaLessThanOrEqualWhere(maxMachineAccessoryLengthInMillimeters.value),
      }
      attachedMachineryAccessoryWhere.some.widthInMillimeters = {
        ...generatePrismaGreaterThanOrEqualWhere(minMachineAccessoryWidthInMillimeters.value),
        ...generatePrismaLessThanOrEqualWhere(maxMachineAccessoryWidthInMillimeters.value),
      }
    }

    if (['sideShifter', 'forkAdjuster', 'forkCarriage'].includes(selectedMachineAccessoryCategory.value)) {
      attachedMachineryAccessoryWhere.some.producerCompanyName = machineAccessoryProducerCompanyName.value ?? undefined
      attachedMachineryAccessoryWhere.some.liftingWeight = {
        ...generatePrismaGreaterThanOrEqualWhere(minMachineAccessoryLiftingWeight.value),
      }
    }

    if (selectedMachineAccessoryCategory.value === 'charger') {
      attachedMachineryAccessoryWhere.some.volt = machineAccessoryVolt.value ?? undefined
    }
  }

  const filters = {
    ...machineryFulltextSearchWhere.value,
    machineryRubric: selectedRubric.value ?? undefined,
    status: 'approval',
    typeId: selectedMachineTypeId.value ?? undefined,
    driveId: selectedDriveId.value ?? undefined,
    ...props.additionalFiltersMachinery,
    ...operatingHoursWhere,
    attachedMachineryAccessories: attachedMachineryAccessoryWhere,
  }

  // Non-offer mode filters
  if (!isOfferCreationMode.value) {
    return filters
  }

  // offer mode filters
  if (offerType.value === 'sale') {
    return { ...filters, isReleasedForSale: true }
  }
  return { ...filters, isReleasedForRent: true }
})

const machineryAccessoryFulltextSearchWhereExtended = computed(() => {
  return {
    ...machineryAccessoryFulltextSearchWhere.value,
    ...props.additionalFiltersMachineryAccessory,
  }
})

const { serverSidePaginationTableProps: machineryServerSidePaginationTableProps, serverSidePagination: machineryQueryPagination, watchForPageCount: machineryWatchForPageCount, watchForReset: machineryWatchForReset } = useTablePagination({ pageCount: 1 })

const { data: machineryCalendarData, error: errorMachinery, isFetching: isFetchingMachinery } = queryCalendar.machinery(
  machineryWhere,
  computed(() => props.additionalOfferFilters),
  selectedStartTime,
  calculatedEndDate,
  isSearchingIncludesOffers,
  visibleTypes,
  numericalFilters,
  showMachineryLocationRow,
  computed(() => props.shouldOnlyIncludeItemsWithOffers),
  machineryQueryPagination,
)

const machinery = computed(() => machineryCalendarData.value?.machinery)
machineryWatchForPageCount(computed(() => machineryCalendarData.value?.totalCount ?? 0))

const machineryFilters = computed(() => ({
  machineryWhere: machineryWhere.value,
  selectedStartTime: selectedStartTime.value,
  calculatedEndDate: calculatedEndDate.value,
  isSearchingIncludesOffers: isSearchingIncludesOffers.value,
  visibleTypes: visibleTypes.value,
  numericalFilters: numericalFilters.value,
  showMachineryLocationRow: showMachineryLocationRow.value,
}))
const stringifiedMachineryFilters = computed(() => JSON.stringify(machineryFilters.value))

machineryWatchForReset(machineryFilters)

const { serverSidePaginationTableProps: accessoryServerSidePaginationTableProps, serverSidePagination: accessoryQueryPagination, watchForPageCount: accessoryWatchForPageCount, watchForReset: accessoryWatchForReset } = useTablePagination({ pageCount: 1 })

const { data: machineryAccessoryCalendarData, error: errorMachineryAccessory, isFetching: isFetchingMachineryAccessory } = queryCalendar.machineryAccessory(
  machineryAccessoryFulltextSearchWhereExtended,
  computed(() => props.additionalOfferFilters),
  selectedStartTime,
  calculatedEndDate,
  isSearchingIncludesOffers,
  visibleTypes,
  selectedMachineryAccessoryCategories,
  fem,
  drive,
  volt,
  wheelSize,
  comment,
  productCode,
  accessoryNumericalFilters,
  computed(() => props.shouldOnlyIncludeItemsWithOffers),
  accessoryQueryPagination,
)

const machineryAccessory = computed(() => machineryAccessoryCalendarData.value?.accessories)
accessoryWatchForPageCount(computed(() => machineryAccessoryCalendarData.value?.totalCount ?? 0))

const machineryAccessoryFilters = computed(() => ({
  machineryAccessoryFulltextSearchWhere: machineryAccessoryFulltextSearchWhereExtended.value,
  selectedStartTime: selectedStartTime.value,
  calculatedEndDate: calculatedEndDate.value,
  isSearchingIncludesOffers: isSearchingIncludesOffers.value,
  visibleTypes: visibleTypes.value,
  selectedMachineryAccessoryCategories: selectedMachineryAccessoryCategories.value,
  accessoryFem: fem.value,
  accessoryNumericalFilters: accessoryNumericalFilters.value,
  accessoryDrive: drive.value,
  accessoryVolt: volt.value,
  accessoryComment: comment.value,
  accessoryProductCode: productCode.value,
  accessoryWheelSize: wheelSize.value,
}))
const stringifiedMachineryAccessoryFilters = computed(() => JSON.stringify(machineryAccessoryFilters.value))

accessoryWatchForReset(machineryAccessoryFilters)

const { itemSetForCalendar } = useTableColumnConfigs()
const { columns: itemSetColumn, fulltextSearch: itemSetFulltextSearch, rowProps: itemSetRowProps } = itemSetForCalendar(props.offerCreationMode.disabledItemSetIds)

const { fulltextSearchValue: itemSetFulltextSearchValue, where: itemSetWhere } = useFilterData(itemSetFulltextSearch)

const { serverSidePaginationTableProps: itemSetServerSidePaginationTableProps, serverSidePagination: itemSetQueryPagination, watchForPageCount: itemSetWatchForPageCount, watchForReset: itemSetWatchForReset } = useTablePagination({ pageCount: 1, pageSize: 10 })

const { data: itemSetCalendarData, error: errorItemSet, isFetching: isFetchingItemSet } = queryCalendar.itemSet(
  itemSetWhere,
  selectedStartTime,
  calculatedEndDate,
  isSearchingIncludesOffers,
  visibleTypes,
  itemSetQueryPagination,
)
itemSetWatchForPageCount(computed(() => itemSetCalendarData.value?.totalCount ?? 0))
itemSetWatchForReset(itemSetWhere)

const isItemSetAvailable = computed(() => Boolean(selectedMachineryToAdd.value || isAccessoryOnlyOffer.value))

const itemSets = computed(() => itemSetCalendarData.value?.itemSets ?? [])

const { data: customers, error: errorCustomers, isFetching: isFetchingCustomers } = queryCustomer.all(undefined, {
  refetchOnWindowFocus: false,
})

const customersById = computed(() => {
  if (customers.value) {
    return Object.fromEntries(customers.value.map(customer => [customer.id, customer]))
  }
  return {}
})

type Color = 'red' | 'yellow' | 'blue' | 'orange' | 'lightOrange' | 'brown' | 'purple' | 'darkGrey' | 'grey' | 'blueRed' | 'white' | 'lightRed' | 'lightYellow' | 'darkRed' | 'lightBlue'
interface CellRenderConfig {
  render: (row: ApiCalendarMachinery | ApiCalendarMachineryAccessory, dateOfColumn: Date) => VNode | undefined
}

/** Checks whether item is `ApiCalendarMachinery` */
const testIsMachinery = (row: ApiCalendarMachinery | ApiCalendarMachineryAccessory) => 'receptionMachinery' in row
function testIsRentalOffer(terminatedDate: Date | null, { type, status, obligationStartsAt, obligationEndsAt, obligationActuallyEndedAt }: ApiCalendarMachinery['relatedOfferPositions'][number]['relatedOffer'], dateOfColumn: Date, desiredStatus: 'offer' | 'order' | 'invoiced') {
  const terminationDate = terminatedDate ?? obligationActuallyEndedAt
  return type === 'rental' && status === desiredStatus && startOfDay(obligationStartsAt) <= startOfDay(dateOfColumn) && ((obligationEndsAt && obligationEndsAt >= dateOfColumn) || (!terminationDate && today >= dateOfColumn) || (terminationDate && terminationDate >= dateOfColumn))
}
function testIsServiceProjectOffer(terminatedDate: Date | null, { type, status, obligationEndsAt }: ApiCalendarMachinery['relatedOfferPositions'][number]['relatedOffer'], dateOfColumn: Date, desiredStatus: 'offer' | 'order' | 'invoiced', deliveryAt?: Date | null) {
  const startDay = deliveryAt ? startOfDay(deliveryAt) : undefined
  return type === 'service-project' && startDay && status === desiredStatus && startDay <= startOfDay(dateOfColumn) && ((obligationEndsAt && obligationEndsAt >= dateOfColumn) || (terminatedDate && terminatedDate >= dateOfColumn))
}
function testIsSpecialOffer({ type, status, obligationStartsAt, obligationEndsAt }: ApiCalendarMachinery['relatedOfferPositions'][number]['relatedOffer'], dateOfColumn: Date, desiredStatus: 'offer' | 'order' | 'invoiced') {
  const startDay = obligationStartsAt ? startOfDay(obligationStartsAt) : undefined
  return type === 'special' && startDay && status === desiredStatus && startOfDay(startDay) <= startOfDay(dateOfColumn) && obligationEndsAt && obligationEndsAt >= dateOfColumn
}
const testIsSaleOffer = ({ type, obligationStartsAt }: ApiCalendarMachinery['relatedOfferPositions'][number]['relatedOffer'], dateOfColumn: Date) => type === 'sale' && startOfDay(obligationStartsAt) <= dateOfColumn
function testIsTerminated(terminatedDate: Date | null, { obligationActuallyEndedAt }: ApiCalendarMachinery['relatedOfferPositions'][number]['relatedOffer'], dateOfColumn: Date) {
  const terminationDate = terminatedDate ?? obligationActuallyEndedAt
  return terminationDate && terminationDate <= dateOfColumn
}

function testIsInternalReservation({ reservationStartsAt, reservationEndsAt }: ApiCalendarMachinery['internalReservations'][number], dateOfColumn: Date) {
  // Soft deleted internal reservations are not fetched
  return startOfDay(reservationStartsAt) <= startOfDay(dateOfColumn) && reservationEndsAt >= dateOfColumn
}

const renderCellText = (text: string, classNames: string) => h(Cell, { text, class: classNames })

type RelatedOfferPosition = ApiCalendarMachinery['relatedOfferPositions'][number] | ApiCalendarMachineryAccessory['relatedOfferPositions'][number]

function getCellText(relatedOfferPosition: RelatedOfferPosition, dateOfColumn: Date) {
  const customerName = customersById.value[relatedOfferPosition.relatedOffer.customerId].name
  const isPositionPaid = relatedOfferPosition.invoices.find((invoice) => {
    return invoice.invoicedDate
      && isSameDay(invoice.invoicedDate, dateOfColumn)
      && invoice.invoice.status === 'paid'
  })

  return `${isPositionPaid ? '💰 ' : ''}${customerName}`
}

const cellRenderConfigs: Record<Color, CellRenderConfig> = {
  // grey: sold machinery
  grey: {
    render: ({ relatedOfferPositions }, dateOfColumn) => {
      const relatedSaleOfferPosition = relatedOfferPositions.find(({ repurchasedAt, relatedOffer }) => {
        if (relatedOffer.status === 'offer') {
          return false
        }

        if (!testIsSaleOffer(relatedOffer, dateOfColumn)) {
          return false
        }

        return repurchasedAt ? isSameDay(relatedOffer.obligationStartsAt, dateOfColumn) : relatedOffer.obligationStartsAt <= dateOfColumn
      })

      if (relatedSaleOfferPosition) {
        return h(CalendarPopoverOffer, { offer: relatedSaleOfferPosition.relatedOffer, invoices: relatedSaleOfferPosition.invoices, customersById: customersById.value }, () => renderCellText(getCellText(relatedSaleOfferPosition, dateOfColumn) || 'N/A', 'w-full h-8 bg-gray-300 items-center flex'))
      }
    },
  },
  // darkGrey: sold machinery signedAbUploaded but offer is not started yet
  darkGrey: {
    render: ({ relatedOfferPositions }, dateOfColumn) => {
      const relatedSaleOfferPosition = relatedOfferPositions.find(({ repurchasedAt, relatedOffer }) => {
        const { signedAbUploadedAt, status, type } = relatedOffer

        if (type !== 'sale' || status === 'offer' || !signedAbUploadedAt || repurchasedAt) {
          return false
        }

        return startOfDay(signedAbUploadedAt) <= dateOfColumn
      })

      if (relatedSaleOfferPosition) {
        return h(CalendarPopoverOffer, { offer: relatedSaleOfferPosition.relatedOffer, invoices: relatedSaleOfferPosition.invoices, customersById: customersById.value }, () => renderCellText(getCellText(relatedSaleOfferPosition, dateOfColumn) || 'N/A', 'w-full h-8 bg-gray-600 text-white items-center flex'))
      }
    },
  },
  // darkRed: permanently not available item
  darkRed: {
    render: (row, dateOfColumn) => {
      const { isPermanentlyNotAvailable, notAvailabledSince } = row

      // Item is not available _today_, iff: (a) it is not available and (b) its unavailability started either today or previously
      const isNotAvailable = Boolean(isPermanentlyNotAvailable && notAvailabledSince && startOfDay(notAvailabledSince) <= dateOfColumn)

      if (isNotAvailable) {
        return h(CalendarPopoverNotAvailable, { item: row }, () => h('div', { class: 'w-full h-8 bg-red-400' }))
      }
    },
  },
  // lightBlue: machinery is reserved for internal use
  lightBlue: {
    render: (row, dateOfColumn) => {
      if (testIsMachinery(row)) {
        const internalReservations = row.internalReservations
        const internalReservation = internalReservations.find(reservation => testIsInternalReservation(reservation, dateOfColumn))
        if (internalReservation) {
          return h(CalendarPopoverReservation, { internalReservation }, () => renderCellText(internalReservation.reservationDescription, 'w-full h-8 bg-blue-200 items-center flex'))
        }
      }
    },
  },
  // yellow: rental or service-project in offer status
  yellow: {
    render: ({ relatedOfferPositions }, dateOfColumn) => {
      const rentalMachineryRelation = relatedOfferPositions.find(({ relatedOffer, terminatedDate }) => testIsRentalOffer(terminatedDate, relatedOffer, dateOfColumn, 'offer'))
      const serviceProjectOffer = relatedOfferPositions.find(({ relatedOffer, terminatedDate, shippedViaLogisticsTasks }) => testIsServiceProjectOffer(terminatedDate, relatedOffer, dateOfColumn, 'offer', shippedViaLogisticsTasks[0]?.deliveryAt))
      const positionRelation = rentalMachineryRelation || serviceProjectOffer
      if (positionRelation) {
        const { relatedOffer, invoices } = positionRelation
        const customerName = customersById.value[relatedOffer.customerId].name

        return h(CalendarPopoverOffer, { offer: relatedOffer, invoices, customersById: customersById.value }, () => renderCellText(customerName || 'N/A', 'w-full h-8 bg-yellow-400 items-center flex'))
      }
    },
  },
  // blue: blue background with red text for termination day
  blueRed: {
    render: ({ relatedOfferPositions }, dateOfColumn) => {
      const positionRelation = relatedOfferPositions.find(({ terminatedDate, relatedOffer, shippedViaLogisticsTasks }) => {
        const testIsRental = testIsRentalOffer(terminatedDate, relatedOffer, dateOfColumn, 'order')
        const testIsServiceProject = testIsServiceProjectOffer(terminatedDate, relatedOffer, dateOfColumn, 'order', shippedViaLogisticsTasks[0]?.deliveryAt)

        if (!testIsRental && !testIsServiceProject) {
          return false
        }

        const terminationDate = terminatedDate ?? relatedOffer.obligationActuallyEndedAt

        if (!terminationDate) {
          return false
        }

        return isSameDay(terminationDate, dateOfColumn)
      })

      if (positionRelation) {
        const { relatedOffer, invoices } = positionRelation

        let cellText = getCellText(positionRelation, dateOfColumn)

        const collectionDay = positionRelation.collectedAt

        if (collectionDay && isSameDay(collectionDay, dateOfColumn)) {
          cellText = `🚚 ${cellText}`
        }

        return h(CalendarPopoverOffer, { offer: relatedOffer, invoices, customersById: customersById.value, dateOfColumn }, () => renderCellText(cellText, 'w-full h-8 bg-blue-300 text-red-600 items-center flex'))
      }
    },
  },
  // light orange: rental is paused
  lightOrange: {
    render: ({ relatedOfferPositions }, dateOfColumn) => {
      const rentalMachineryRelation = relatedOfferPositions.find(({ relatedOffer, terminatedDate }) => testIsRentalOffer(terminatedDate, relatedOffer, dateOfColumn, 'order'))
      if (rentalMachineryRelation) {
        const { relatedOffer, invoices } = rentalMachineryRelation
        if (relatedOffer.rentalPauses.some(pause => startOfDay(pause.startDate) <= dateOfColumn && startOfDay(pause.endDate) >= dateOfColumn)) {
          return h(CalendarPopoverOffer, { offer: relatedOffer, invoices, customersById: customersById.value, dateOfColumn }, () => renderCellText(getCellText(rentalMachineryRelation, dateOfColumn), 'w-full h-8 bg-orange-100 items-center flex'))
        }
      }
    },
  },
  // brown: this specific rental day has 100% discount rate applied
  brown: {
    render: ({ relatedOfferPositions }, dateOfColumn) => {
      const rentalMachineryRelation = relatedOfferPositions.find(({ terminatedDate, relatedOffer }) => {
        if (!testIsRentalOffer(terminatedDate, relatedOffer, dateOfColumn, 'order')) {
          return false
        }

        if (!relatedOffer.rentalDays) {
          return false
        }

        const rentalDay = relatedOffer.rentalDays.find(rentalDay => isSameDay(rentalDay.date, dateOfColumn))
        if (!rentalDay) {
          return false
        }

        if (testIsTerminated(terminatedDate, relatedOffer, dateOfColumn)) {
          return false
        }

        return rentalDay.discountRate === 1
      })

      if (rentalMachineryRelation) {
        const { relatedOffer, invoices } = rentalMachineryRelation

        return h(CalendarPopoverOffer, { offer: relatedOffer, invoices, customersById: customersById.value, dateOfColumn }, () => renderCellText(getCellText(rentalMachineryRelation, dateOfColumn), 'w-full h-8 bg-brown-700 text-white items-center flex'))
      }
    },
  },
  // purple: this specific rental day has some discount rate applied
  purple: {
    render: ({ relatedOfferPositions }, dateOfColumn) => {
      const rentalMachineryRelation = relatedOfferPositions.find(({ relatedOffer, terminatedDate }) => {
        if (!testIsRentalOffer(terminatedDate, relatedOffer, dateOfColumn, 'order')) {
          return false
        }

        if (!relatedOffer.rentalDays) {
          return false
        }

        const rentalDay = relatedOffer.rentalDays.find(rentalDay => isSameDay(rentalDay.date, dateOfColumn))

        if (!rentalDay) {
          return false
        }

        if (testIsTerminated(terminatedDate, relatedOffer, dateOfColumn)) {
          return false
        }

        return rentalDay.discountRate !== 0
      })

      if (rentalMachineryRelation) {
        const { relatedOffer, invoices } = rentalMachineryRelation

        return h(CalendarPopoverOffer, { offer: relatedOffer, invoices, customersById: customersById.value, dateOfColumn }, () => renderCellText(getCellText(rentalMachineryRelation, dateOfColumn), 'w-full h-8 bg-purple-700 text-white items-center flex'))
      }
    },
  },
  // blue: rental or service-project is in order status and not terminated yet
  blue: {
    render: ({ relatedOfferPositions }, dateOfColumn) => {
      const rentalMachineryRelation = relatedOfferPositions.find(({ relatedOffer, terminatedDate, shippedViaLogisticsTasks }) => {
        const testIsRental = testIsRentalOffer(terminatedDate, relatedOffer, dateOfColumn, 'order')
        const testIsServiceProject = testIsServiceProjectOffer(terminatedDate, relatedOffer, dateOfColumn, 'order', shippedViaLogisticsTasks[0]?.deliveryAt)
        const testIsSpecial = testIsSpecialOffer(relatedOffer, dateOfColumn, 'order')

        if (!testIsRental && !testIsServiceProject && !testIsSpecial) {
          return false
        }

        if (testIsTerminated(terminatedDate, relatedOffer, dateOfColumn)) {
          return false
        }

        if (testIsRental) {
          return relatedOffer.obligationEndsAt && dateOfColumn <= relatedOffer.obligationEndsAt
        }

        return true
      })

      if (rentalMachineryRelation) {
        const { relatedOffer, invoices } = rentalMachineryRelation

        return h(CalendarPopoverOffer, { offer: relatedOffer, invoices, customersById: customersById.value, dateOfColumn }, () => renderCellText(getCellText(rentalMachineryRelation, dateOfColumn), 'w-full h-8 bg-blue-300 items-center flex'))
      }
    },
  },
  // red: defective machinery
  red: {
    render: (row, dateOfColumn) => {
      const dateDefectStarted = row.defects.sort((a, b) => sortNullishDates(a.createdAt, b.createdAt))[0]?.createdAt

      // Item is defective _today_, iff: (a) it is defective and (b) the defect started either today or previously
      const isDefective = Boolean(row.isDefective && dateDefectStarted && startOfDay(dateDefectStarted) <= dateOfColumn)

      if (isDefective) {
        if (testIsMachinery(row)) {
          return h(CalendarPopoverRepairOrReleaseMachinery, { machinery: row }, () => h('div', { class: 'w-full h-8 bg-red-300' }))
        } else {
          return h(CalendarPopoverRepairMachineryAccessory, { machineryAccessory: row }, () => h('div', { class: 'w-full h-8 bg-red-300' }))
        }
      }
    },
  },
  /**
   * white: rental has prolonged(extended)
   * 1. terminatedDate is not defined but obligationEndsAt has passed
   * 2. obligationEndsAt date has passed but terminatedDate is not passed yet
   * 3. terminatedDate is later than obligationEndsAt
   */
  white: {
    render: ({ relatedOfferPositions }, dateOfColumn) => {
      const rentalMachineryRelation = relatedOfferPositions.find(({ terminatedDate, relatedOffer }) => {
        const terminationDate = terminatedDate ?? relatedOffer.obligationActuallyEndedAt
        const isTerminatedDateLaterThanDateOfColumn = terminationDate && dateOfColumn < terminationDate
        const isTerminated = testIsTerminated(terminatedDate, relatedOffer, dateOfColumn)

        if (!testIsRentalOffer(terminatedDate, relatedOffer, dateOfColumn, 'order') || (!isTerminatedDateLaterThanDateOfColumn && isTerminated)) {
          return false
        }

        return true
      })

      if (rentalMachineryRelation) {
        const { relatedOffer, invoices } = rentalMachineryRelation

        return h(CalendarPopoverOffer, { offer: relatedOffer, invoices, customersById: customersById.value, dateOfColumn }, () => renderCellText(getCellText(rentalMachineryRelation, dateOfColumn), 'w-full h-8 items-center flex text-red-600'))
      }
    },
  },
  // orange: rental or service-project is invoiced
  orange: {
    render: ({ relatedOfferPositions }, dateOfColumn) => {
      const rentalMachineryRelation = relatedOfferPositions.find(({ relatedOffer, terminatedDate }) => testIsRentalOffer(terminatedDate, relatedOffer, dateOfColumn, 'invoiced'))
      const serviceProjectRelation = relatedOfferPositions.find(({ relatedOffer, terminatedDate, shippedViaLogisticsTasks }) => testIsServiceProjectOffer(terminatedDate, relatedOffer, dateOfColumn, 'invoiced', shippedViaLogisticsTasks[0]?.deliveryAt))
      const positionRelation = rentalMachineryRelation || serviceProjectRelation

      if (positionRelation) {
        const { relatedOffer, invoices } = positionRelation

        return h(CalendarPopoverOffer, { offer: relatedOffer, invoices, customersById: customersById.value, dateOfColumn }, () => renderCellText(getCellText(positionRelation, dateOfColumn), 'w-full h-8 bg-orange-300 items-center flex'))
      }
    },
  },
  // lightRed: position is currently unavailable
  lightRed: {
    render: ({ id, relatedOfferPositions }, dateOfColumn) => {
      let latestLoadedAtRelevantPosition
      for (const position of relatedOfferPositions) {
        const relevantPositionOrItemSetData = position.type === 'itemSetAccessory' && position.itemSetPosition
          ? { ...position, loadedAt: position.itemSetPosition.loadedAt, returnedAt: position.itemSetPosition.returnedAt, terminatedDate: position.itemSetPosition.terminatedDate }
          : position

        if (!relevantPositionOrItemSetData.loadedAt) {
          continue
        }

        const { type, machineryId, machineryAccessoryId, loadedAt } = relevantPositionOrItemSetData

        const isMachinery = type === 'machinery' && machineryId === id
        const isAccessory = type === 'machineryAccessory' && machineryAccessoryId === id
        const isItemSetAccessory = type === 'itemSetAccessory' && machineryAccessoryId === id

        // Skip if position is not relevant
        const isRelevantPosition = (isMachinery || isAccessory || isItemSetAccessory) && startOfDay(loadedAt) <= dateOfColumn
        if (!isRelevantPosition) {
          continue
        }

        if (latestLoadedAtRelevantPosition && latestLoadedAtRelevantPosition.loadedAt >= relevantPositionOrItemSetData.loadedAt) {
          continue
        }

        // TS does not correctly infer that `relevantPositionOrItemSetData.loadedAt` is of type `Date` here, but knows that `loadedAt: Date`
        latestLoadedAtRelevantPosition = { ...relevantPositionOrItemSetData, loadedAt }
      }

      if (!latestLoadedAtRelevantPosition) {
        return
      }

      const { loadedAt, returnedAt, relatedOffer, terminatedDate } = latestLoadedAtRelevantPosition

      const { obligationEndsAt, obligationActuallyEndedAt } = relatedOffer
      const terminationDate = terminatedDate ?? obligationActuallyEndedAt

      // No end date --> sale
      if (!obligationEndsAt) {
        return
      }

      const lightRedCell = h('div', { class: 'w-full h-8 bg-red-100' })

      if (!terminationDate || !returnedAt) {
        return startOfDay(loadedAt) <= dateOfColumn ? lightRedCell : undefined
      }

      // If `returnedAt` is before or the same as `terminationDate`, `isWithinInterval` cannot be used
      if (startOfDay(returnedAt) <= startOfDay(terminationDate)) {
        return
      }

      return isWithinInterval(dateOfColumn, { start: startOfDay(loadedAt), end: addDaysDstSafe(startOfDay(returnedAt), -1) }) ? lightRedCell : undefined
    },
  },
  // lightYellow: machinery not released for rental
  lightYellow: {
    render: (row) => {
      if (testIsMachinery(row) && !row.isReleasedForRent) {
        return h(CalendarPopoverRepairOrReleaseMachinery, { machinery: row }, () => h('div', { class: 'w-full h-8 bg-yellow-200' }))
      }
    },
  },
}

function makeColumnRenderFunc(date: Date) {
  return (row: ApiCalendarMachinery | ApiCalendarMachineryAccessory) => {
    if (row.locationHistory?.length) {
      const index = differenceInDays(date, selectedStartTime.value)
      return h(CalendarPopoverPositionLocation, { position: row, dateOfColumn: date, locationHistoryIndex: index, customersById: customersById.value }, () => renderCellText(row.locationHistory?.length ? row.locationHistory[index] : 'N/A', 'w-full h-8 items-center justify-center flex'))
    }

    for (const { render } of Object.values(cellRenderConfigs)) {
      const renderCell = render(row, date)
      if (renderCell) {
        return renderCell
      }
    }

    // `green` is fallback
    return h('div', { class: 'w-full h-8 bg-green-300' })
  }
}

function makeCalendarColumn(date: Date): DataTableColumn<ApiCalendarMachinery | ApiCalendarMachineryAccessory> {
  return {
    title() {
      const weekDay = useDateAsString(date, 'EEEE')
      const titleContent = [useDateAsString(date, 'dd.MM'), h('span', { class: 'text-xs -translate-y-0.5' }, weekDay)]
      if (date.toDateString() === today.toDateString()) {
        return h('div', { class: 'h-10 grid place-items-center border-2 border-black font-bold' }, titleContent)
      }
      return h('div', { class: 'h-10 grid place-items-center border-2 border-transparent' }, titleContent)
    },
    width: CELL_WIDTH_PX,
    key: 'name',
    render: makeColumnRenderFunc(date),
    className: `${isSameDay(date, today) ? 'TodayColumn' : ''}`,
  }
}

const machinerySelectionColumn: DataTableColumn<ApiCalendarMachinery> = {
  type: 'selection',
  multiple: false,
  disabled: row => row.isPermanentlyNotAvailable || disabledMachineryIds.value.includes(row.id),
}
const machineryColumns = computed((): DataTableColumns<ApiCalendarMachinery> => {
  const columns: DataTableColumns<ApiCalendarMachinery> = [{
    title() {
      return h('div', { class: 'pl-2' }, 'ID')
    },
    key: 'id',
    fixed: 'left',
    width: 100,
    minWidth: 100,
    render: (machinery) => {
      let classes = 'px-2 truncate'

      if ((machinery.receptionMachinery?.liftingWeight ?? 0) < (minLiftingWeight.value ?? 0) || (machinery.receptionMachinery?.liftingHeightInMillimeters ?? 0) < (minLiftingHeight.value ?? 0)) {
        classes += ' opacity-50'
      }
      return h(CalendarPopoverMachinery, { machinery }, () => h('span', { class: classes }, machinery.id))
    },
    rowSpan: ({ id }) => machineryById.value[id].machineryRowCount ?? 1,
  }, {
    title() {
      return h('div', { class: 'pl-2' }, 'Hersteller')
    },
    minWidth: 175,
    width: 175,
    fixed: 'left',
    key: 'producerCompanyName',
    ellipsis: { tooltip: true },
    render: ({ producerCompanyName, receptionMachinery }) => {
      let classes = 'px-2'

      if ((receptionMachinery?.liftingWeight ?? 0) < (minLiftingWeight.value ?? 0) || (receptionMachinery?.liftingHeightInMillimeters ?? 0) < (minLiftingHeight.value ?? 0)) {
        classes += ' opacity-50'
      }

      return h('span', { class: classes }, producerCompanyName)
    },
    rowSpan: ({ id }) => machineryById.value[id].machineryRowCount ?? 1,
  }, {
    title() {
      return h('div', { class: 'pl-2' }, 'Typ')
    },
    minWidth: 125,
    width: 125,
    fixed: 'left',
    key: 'type',
    ellipsis: { tooltip: true },
    render: ({ type, receptionMachinery }) => {
      let classes = 'px-2'

      if ((receptionMachinery?.liftingWeight ?? 0) < (minLiftingWeight.value ?? 0) || (receptionMachinery?.liftingHeightInMillimeters ?? 0) < (minLiftingHeight.value ?? 0)) {
        classes += ' opacity-50'
      }

      return h('span', { class: classes }, type.name)
    },
    rowSpan: ({ id }) => machineryById.value[id].machineryRowCount ?? 1,
  }, ...Array.from({ length: daysToRender }, (_, idx) => makeCalendarColumn(addDaysDstSafe(selectedStartTime.value, idx)))]

  if (isOfferCreationMode.value) {
    columns.unshift(machinerySelectionColumn)
  }

  return columns
})

const machineryAccessoryColumns = computed((): DataTableColumns<ApiCalendarMachineryAccessory> => [
  {
    title() {
      return h('div', { class: 'pl-2' }, 'ID')
    },
    key: 'id',
    fixed: 'left',
    width: 100,
    minWidth: 100,
    render: machineryAccessory => h(CalendarPopoverMachineryAccessory, { machineryAccessory }, () => h('span', { class: 'px-2 truncate' }, machineryAccessory.id)),
  },
  {
    title() {
      return h('div', { class: 'pl-2' }, 'Kategorie')
    },
    minWidth: 175,
    width: 175,
    fixed: 'left',
    key: 'category',
    ellipsis: { tooltip: true },
    render: ({ category }) => h('span', { class: 'px-2' }, machineryAccessoryCategoryToGerman[category as MachineryAccessoryCategory]),
  },
  {
    title() {
      return h('div', { class: 'pl-2' }, 'Typ')
    },
    minWidth: 125,
    width: 125,
    fixed: 'left',
    key: 'typeName',
    ellipsis: { tooltip: true },
    render: ({ typeName }) => h('span', { class: 'px-2' }, typeName ?? ''),
  },
  ...Array.from({ length: daysToRender }, (_, idx) => makeCalendarColumn(addDaysDstSafe(selectedStartTime.value, idx))),
])

export interface CompareMachineryAccessories {
  oldMachinery: ApiMachineryGetById
  newMachinery: ApiMachineryGetById
  checkedMachineryAccessoryKeys: string[]
}

const allowAddToOffer = computed(() => {
  if (offerType.value === 'rental' && isForkEditMode.value) {
    return false
  }

  // machinery is selected
  if (checkedMachineryKeys.value.length > 0) {
    return true
  }
  // accessory only offer is selected and
  // there are accessories in the cart or
  // set(s) of accessories is(are) selected
  return isAccessoryOnlyOffer.value && (cartPositions.value.length > 0 || checkedItemSetKeys.value.length > 0)
})

const popupConfirmRemovePositionWhereLogisticsWereAlreadyPlanned = ref<null | CalendarPageOfferCreationModeEmit>(null)
const notSelectedPlannedPositions = ref([] as DataTableRowKey[])
function emitAddToOffer() {
  if (!selectedMachineryToAdd.value && cartPositions.value.length === 0 && !checkedItemSetKeys.value) {
    return
  }

  const operatingHours = selectedMachineryToAdd.value?.receptionMachinery?.operatingHours ?? 0

  // Assemble specific machinery accessory instances - required because we need to enrich the data with categoy-information
  const machineryAccessoryPositionsEnrichedWithCategoryInfo = cartPositions.value
    .filter(p => p.type === 'machineryAccessory')
    .map((p) => {
      const accessory = detailsForMachineryAccessoriesInCart.value?.find(d => d.id === p.machineryAccessoryId)
      let machineryAccessoryCategory = accessory?.category
      if (!machineryAccessoryCategory) {
        machineryAccessoryCategory = 'N/A'
      } else if (machineryAccessoryCategory === 'miscellaneous') {
        machineryAccessoryCategory = accessory?.description ?? machineryAccessoryCategory
      }

      return {
        ...p,
        machineryAccessoryCategory,
      }
    })

  const machineryAccessoryCategoryPositions = cartPositions.value.filter(p => p.type === 'machineryAccessoryCategory')

  const selectedItems = {
    machinery: selectedMachineryToAdd.value
      ? { id: selectedMachineryToAdd.value.id, producerCompanyName: selectedMachineryToAdd.value.producerCompanyName, type: { name: selectedMachineryToAdd.value.type.name }, operatingHours }
      : null,
    cartPositions: [...machineryAccessoryPositionsEnrichedWithCategoryInfo, ...machineryAccessoryCategoryPositions],
    itemSets: selectedItemSetsToAdd.value,
    rentalDuration: rentalDuration.value,
    selectedStartTime: new Date(selectedStartTime.value),
    isAccessoryOnlyOffer: isAccessoryOnlyOffer.value,
  }

  if (props.offerCreationMode.alreadyPlannedPositionKeys?.length) {
    const machineryAccessoryCategories = selectedItems.cartPositions.filter(p => p.type === 'machineryAccessoryCategory').map(p => p.machineryAccessoryCategory)
    const machineryAccessoryIds = selectedItems.cartPositions.map(p => p.type === 'machineryAccessory' ? p.machineryAccessoryId : undefined).filter(id => id !== undefined)

    const allKeys = [...(selectedMachineryToAdd.value ? [selectedMachineryToAdd.value.id] : []), ...machineryAccessoryCategories, ...machineryAccessoryIds, ...checkedItemSetKeys.value]
    const notSelectedAlreadyPlannedPositions = props.offerCreationMode.alreadyPlannedPositionKeys.filter(key => !allKeys.includes(key as string))
    if (notSelectedAlreadyPlannedPositions.length > 0) {
      notSelectedPlannedPositions.value = notSelectedAlreadyPlannedPositions
      popupConfirmRemovePositionWhereLogisticsWereAlreadyPlanned.value = selectedItems
      return
    }
  }

  if (props.offerCreationMode.originalOfferId) {
    createFromOtherOffer.mutate({
      offerId: props.offerCreationMode.originalOfferId,
      calendarPositions: selectedItems,
      deliveryAt: new Date(deliveryAt.value),
    })
    return
  }

  const { groupInOffer, groupMode } = props.offerCreationMode
  emit('addToOffer', selectedItems, offerType.value, groupInOffer, groupMode)
}

/**
 * This will watch checkedMachineryKey to set cartPositions to the attached accessory ids and clear everything else when the machiney changes
 * This will only run when checkedMachineryKeys is updated to prevent overwriting pre-selected machineryAccessory. See https://github.com/sidestream-tech/hanselmann-os/pull/402#discussion_r1226234712 for a discussion on this
 */
watch(checkedMachineryKeys, (newMachineryKeys) => {
  isMachineryUpdated.value = true

  if (!newMachineryKeys) {
    return
  }

  if (props.offerCreationMode.offerStatus === 'order' && props.offerCreationMode.isOfferEditMode) {
    return
  }

  cartPositions.value = selectedMachineryToAdd.value?.attachedMachineryAccessories.map(a => ({ type: 'machineryAccessory', machineryAccessoryId: a.id })) ?? []
})

const cartPositions = ref<ShoppingCartPosition[]>(props.offerCreationMode.shoppingCartPositions)

const machineryAccessoryWhere = computed(() => {
  const allMachineryAccessoriesInCart = cartPositions.value.filter(p => p.type === 'machineryAccessory')

  return {
    id: {
      in: allMachineryAccessoriesInCart.map(a => a.machineryAccessoryId),
    },
    ...props.additionalFiltersMachineryAccessory,
  }
})

const { data: detailsForMachineryAccessoriesInCart } = queryMachineryAccessory.all(machineryAccessoryWhere)

const { payload: comparePayload, open: openComparePopup } = usePopup<CompareMachineryAccessories>()
const hasMachineryUpdateCancelled = ref(false)
const isMachineryUpdated = ref(false)

function addMachineryAttachedAccessoriesToCartPositions(attachedMachineryAccessories: { id: string, category: string }[]) {
  if (!detailsForMachineryAccessoriesInCart.value) {
    return
  }

  const selectedForkIndex = cartPositions.value.findIndex(p => detailsForMachineryAccessoriesInCart.value.some(acc => p.type === 'machineryAccessory' && acc.id === p.machineryAccessoryId && acc.category === 'fork'))
  const attachedFork = attachedMachineryAccessories.find(({ category }) => category === 'fork')

  const attachedMachineryAccessoriesToAdd = selectedForkIndex !== -1 && attachedFork ? attachedMachineryAccessories.filter(({ category }) => category !== 'fork') : attachedMachineryAccessories
  const attachedMachineryAccessoryId = attachedMachineryAccessoriesToAdd.map(({ id }) => id)
  const attachedMachineryAccessoryIdsToAdd = attachedMachineryAccessoryId.filter(id => !cartPositions.value.some(p => p.type === 'machineryAccessory' && p.machineryAccessoryId === id))

  // If connected fork to machinery has changed, we update `isForkReplaceRequired` value
  const selectedFork = cartPositions.value[selectedForkIndex]
  if (selectedFork?.type === 'machineryAccessory') {
    if (selectedFork.machineryAccessoryId === attachedFork?.id) {
      cartPositions.value[selectedForkIndex].isForkReplaceRequired = false
    } else {
      cartPositions.value[selectedForkIndex].isForkReplaceRequired = true
    }
  }

  cartPositions.value = cartPositions.value.concat(attachedMachineryAccessoryIdsToAdd.map(machineryAccessoryId => ({ type: 'machineryAccessory', machineryAccessoryId })))
}

watch(detailsForMachineryAccessoriesInCart, (newAccessories, oldAccessories) => {
  if (!oldAccessories && newAccessories && selectedMachineryToAdd.value && isMachineryUpdated.value) {
    const isMachineryUpdateOffer = props.offerCreationMode.alreadyCheckedMachineryKeys?.[0] && props.offerCreationMode.isOfferEditMode
    const attachedMachineryAccessories = selectedMachineryToAdd.value.attachedMachineryAccessories
    if (isMachineryUpdateOffer && attachedMachineryAccessories) {
      addMachineryAttachedAccessoriesToCartPositions(attachedMachineryAccessories)
    }
  }
})

watch(selectedMachineryToAdd, (newMachinery, oldMachinery) => {
  if (!newMachinery || newMachinery.id === oldMachinery?.id || !isMachineryUpdated.value) {
    return
  }

  if (hasMachineryUpdateCancelled.value) {
    hasMachineryUpdateCancelled.value = false
    return
  }

  if (!oldMachinery) {
    addMachineryAttachedAccessoriesToCartPositions(newMachinery.attachedMachineryAccessories)
    return
  }

  if (props.offerCreationMode.offerStatus === 'order' && props.offerCreationMode.isOfferEditMode) {
    openComparePopup({
      oldMachinery,
      newMachinery,
      checkedMachineryAccessoryKeys: cartPositions.value.filter(p => p.type === 'machineryAccessory').map(p => p.machineryAccessoryId),
    })
  } else {
    addMachineryAttachedAccessoriesToCartPositions(newMachinery.attachedMachineryAccessories)
  }
})

function closeComparePopup(payload?: string[]) {
  if (payload) {
    // 1. If a payload is returned, we overwrite the selected accessories and do not reset the new selected machinery
    const machineryAccessoryCategoryCartPositions = cartPositions.value.filter(p => p.type === 'machineryAccessoryCategory')
    const machineryAccessoryCartPositions: ShoppingCartPositionAccessory[] = payload.map(machineryAccessoryId => ({ type: 'machineryAccessory', machineryAccessoryId }))
    cartPositions.value = [...machineryAccessoryCategoryCartPositions, ...machineryAccessoryCartPositions]
  } else {
    // 2. Otherwise, we reset the selection to the passed in machinery id (and by extension the selected accessories)
    checkedMachineryKeys.value = comparePayload.value?.oldMachinery.id
      ? [comparePayload.value.oldMachinery.id]
      : props.offerCreationMode.alreadyCheckedMachineryKeys
    hasMachineryUpdateCancelled.value = true
  }

  comparePayload.value = null
}

export type ShoppingTab = 'compatible' | 'incompatible' | 'attachable'
const shoppingTab = ref<ShoppingTab>(props.offerCreationMode.isAccessoryOnlyOffer ? 'incompatible' : 'compatible')
function selectShoppingTab(tab: ShoppingTab) {
  shoppingTab.value = tab
}
export type ShoppingAccessoryCategory = 'compatible' | 'incompatible' | 'attachable'
const shoppingAccessoryCategory = ref<null | MachineryAccessoryCategory>(null)
function selectShoppingAccessoryCategory(category: null | MachineryAccessoryCategory) {
  shoppingAccessoryCategory.value = category
}

const isForkEditMode = ref(false)
function updateIsForkEditMode(value: boolean) {
  isForkEditMode.value = value
}

const popupConfirmConvertToAccessoryOnlyOffer = ref<null | { isAccessoryOnlyOffer: boolean }>(null)
function updateIsAccessoryOnlyOffer(value: boolean) {
  if (cartPositions.value.length > 0 || checkedMachineryKeys.value.length > 0) {
    popupConfirmConvertToAccessoryOnlyOffer.value = { isAccessoryOnlyOffer: value }
  } else {
    isAccessoryOnlyOffer.value = value
  }
}
function resetShoppingCartAndMachinerySelections(newIsAccessoryOnlyOffer: boolean) {
  cartPositions.value = []
  checkedMachineryKeys.value = []
  checkedItemSetKeys.value = []
  isAccessoryOnlyOffer.value = newIsAccessoryOnlyOffer
  popupConfirmConvertToAccessoryOnlyOffer.value = null
}

watch(isAccessoryOnlyOffer, (newIsAccessoryOnlyOffer, oldIsAccesoryOnlyOffer) => {
  if (newIsAccessoryOnlyOffer === oldIsAccesoryOnlyOffer || !newIsAccessoryOnlyOffer) {
    return
  }
  shoppingTab.value = 'incompatible'
})

function removeSet(id: string) {
  checkedItemSetKeys.value = checkedItemSetKeys.value.filter(key => key !== id)
}

const selecteAccessoryIds = computed(() => {
  return cartPositions.value.filter(p => p.type === 'machineryAccessory').map(p => p.machineryAccessoryId)
})

const showAccessoryIdSearchPopup = ref(false)
const accessoryIdSearchFilters = computed(() => {
  if (offerType.value === 'special') {
    return { status: { in: ['approval'] }, category: 'storageSpace' }
  }
  return { status: { in: ['approval'] } }
})
function saveAccessoryIdsFromSearch(ids: string[]) {
  const newIds = ids.filter(id => !selecteAccessoryIds.value.includes(id))
  const newCartPositions = cartPositions.value.filter((position) => {
    if (position.type !== 'machineryAccessory') {
      return true
    }

    if (!ids.includes(position.machineryAccessoryId)) {
      return false
    }

    return true
  })

  const newMachineryAccessories = newIds.map(id => ({
    type: 'machineryAccessory',
    machineryAccessoryId: id,
  })) satisfies ShoppingCartPosition[]

  cartPositions.value = [
    ...newCartPositions,
    ...newMachineryAccessories,
  ]

  showAccessoryIdSearchPopup.value = false
}
</script>

<template>
  <div>
    <ShoppingAccessoryIdSearchPopup
      v-if="showAccessoryIdSearchPopup"
      :show="showAccessoryIdSearchPopup"
      :additional-accessory-filters="accessoryIdSearchFilters"
      :machinery-id="selectedMachineryToAdd?.id"
      :offer-start-day="new Date(selectedStartTime)"
      :offer-end-day="rentalEndDate"
      :offer="{
        status: offerCreationMode.offerStatus ?? 'offer',
        id: offerCreationMode.offerId ?? undefined,
        type: offerCreationMode.offerType,
      }"
      :selected-accessory-ids="selecteAccessoryIds"
      :disabled-accessory-ids="disabledAccessoryIds"
      @save="saveAccessoryIdsFromSearch"
      @close="showAccessoryIdSearchPopup = false"
    />

    <TheConfirmPopup
      v-if="popupConfirmConvertToAccessoryOnlyOffer"
      @confirm="resetShoppingCartAndMachinerySelections(popupConfirmConvertToAccessoryOnlyOffer.isAccessoryOnlyOffer)"
      @close="popupConfirmConvertToAccessoryOnlyOffer = null"
    >
      Sie verlieren alle ausgewählten Geräte und Anbau-Geräte. Sind Sie sicher, dass Sie sie löschen wollen?
    </TheConfirmPopup>
    <TheConfirmPopup
      v-if="popupConfirmRemovePositionWhereLogisticsWereAlreadyPlanned"
      @confirm="emit('addToOffer', popupConfirmRemovePositionWhereLogisticsWereAlreadyPlanned, offerType)"
      @close="popupConfirmRemovePositionWhereLogisticsWereAlreadyPlanned = null"
    >
      {{ notSelectedPlannedPositions.join(', ') }} {{ notSelectedPlannedPositions.length > 1 ? 'sind' : 'ist' }} bereits geplant. Sind Sie sicher, dass Sie sie löschen wollen?
    </TheConfirmPopup>
    <ThePopup v-if="comparePayload" :show="Boolean(comparePayload)" title="Gerät tauschen" width="50%" @close="closeComparePopup">
      <CalendarChangeOfferMachinery
        :payload="comparePayload"
        @keep="payload => closeComparePopup(payload)"
        @discard="payload => closeComparePopup(payload)"
        @cancel="closeComparePopup"
      />
    </ThePopup>

    <!-- Switch between calendar and offer mode -->
    <TheDataCard v-if="showSettings" class="flex flex-col gap-2">
      <!-- Global settings -->
      <h3 class="text-xl">
        Einstellungen
      </h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
        <div class="items-center">
          <p class="mr-4 font-semibold">
            Start-Datum:
          </p>
          <n-date-picker v-model:value="selectedStartTime" type="date" class="w-full" label="start" />
        </div>

        <div>
          <p class="font-semibold">
            Auftragsarten
          </p>
          <n-select v-model:value="visibleTypes" multiple :options="visibleTypesOptions" />
        </div>

        <div class="flex items-end">
          <n-checkbox v-model:checked="isSearchingIncludesOffers" type="checkbox" class="shrink-0">
            <span class="font-semibold">
              Nicht erteilte Aufträge anzeigen
            </span>
          </n-checkbox>
        </div>
      </div>

      <div v-if="offerCreationMode.originalOfferId" class="grid grid-cols-3 gap-2 mt-2">
        <div>
          <p class="mr-4 font-semibold">
            Anlieferdatum und Zeit:
          </p>
          <n-date-picker
            v-model:value="deliveryAt"
            type="datetime"
            label="Anlieferdatum und Zeit"
            :is-date-disabled="(curr: number) => isAfter(curr, addDaysDstSafe(selectedStartTime, 1))"
          />
        </div>
      </div>

      <!-- Offer creation mode -->
      <div v-if="allowOfferCreationMode" class="mt-6">
        <h3 class="text-lg mb-4">
          Angebots- und Verkaufs-Erstellungs-Modus
        </h3>

        <div class="flex flex-col">
          <n-checkbox v-model:checked="isOfferCreationMode" :disabled="props.offerCreationMode.disableOfferTypeSwitch || !((canAccessPage('/rental') || canAccessPage('/sale')))" class="mb-4 " type="checkbox">
            <span class="font-semibold">
              Modus aktivieren
            </span>
          </n-checkbox>

          <div v-if="isOfferCreationMode" class="w-4/5 flex items-end font-semibold gap-2">
            <div class="w-1/3">
              <p>
                Auftragsart
              </p>
              <n-select v-model:value="offerType" :disabled="props.offerCreationMode.disableOfferTypeSwitch" class="w-full" :options="creationTypesOptions" />
            </div>
            <div v-if="offerType === 'rental'" class="w-1/3">
              <p class="mr-4">
                Gesamt-Tage ab Start-Datum:
              </p>
              <n-input-number v-model:value="rentalDuration" clearable />
            </div>
            <n-checkbox
              v-if="isOfferCreationMode"
              :checked="isAccessoryOnlyOffer"
              :disabled="offerCreationMode.isAccessoryOnlyOfferDisabled || offerType === 'special'"
              class="w-1/3"
              @update-checked="updateIsAccessoryOnlyOffer(!isAccessoryOnlyOffer)"
            >
              <span class="font-semibold">
                Nur Lagertool Geräte vermieten / verkaufen
              </span>
            </n-checkbox>
          </div>
        </div>
      </div>
    </TheDataCard>

    <div v-if="offerType !== 'special'" class="mt-4 flex flex-col gap-y-4">
      <!-- Machinery -->
      <TheDataCard v-if="!(isOfferCreationMode && isAccessoryOnlyOffer) && ['machinery', 'all'].includes(showCalendarFor)">
        <h3 class="text-xl">
          Geräte
        </h3>
        <TableFilters v-model="machineryFulltextSearchValue" class="MachineryTable" placeholder="Suchbegriff eingeben (ID, Lieferant, Hersteller, Typ, Seriennummer, Hubhöhe, Tragkraft, Gabellänge, Lager, Ansprechpartner, Firma, Besteller)">
          <template #search>
            <n-collapse v-if="showMachineryFilters">
              <n-collapse-item>
                <template #header>
                  <span class="text-lg">
                    Geräte-spezifische Filter ausklappen
                  </span>
                </template>
                <div class="flex flex-col gap-2 bg-gray-200 p-3 pb-0 rounded-sm">
                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        Rubrik
                      </p>
                      <n-select
                        v-model:value="selectedRubric"
                        :options="rubricOptions"
                        :consistent-menu-width="false"
                        filterable
                        clearable
                      />
                    </div>
                    <div>
                      <p class="font-semibold">
                        Gerätetyp
                      </p>
                      <n-select
                        v-model:value="selectedMachineTypeId"
                        :options="machineryTypeOptions"
                        :consistent-menu-width="false"
                        filterable
                        clearable
                      />
                    </div>
                    <div>
                      <p class="font-semibold">
                        Antrieb
                      </p>
                      <n-select
                        v-model:value="selectedDriveId"
                        :options="machineryDrivesOptions"
                        :consistent-menu-width="false"
                        filterable
                        clearable
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        Tragkraft (kg)
                      </p>
                      <n-input-number
                        v-model:value="minLiftingWeight"
                        type="text"
                        prefix="min"
                        :min="0"
                        placeholder="50"
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Hubhöhe (mm)
                      </p>
                      <n-input-number
                        v-model:value="minLiftingHeight"
                        type="text"
                        :min="0"
                        placeholder="10000"
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Baubreite (mm)
                      </p>
                      <n-input-number
                        v-model:value="maxWidthInMillimeters"
                        type="text"
                        :min="0"
                        placeholder="4000"
                      >
                        <template #prefix>
                          {{ $t('general.max') }}
                        </template>
                      </n-input-number>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        Arbeitshöhe (mm)
                      </p>
                      <n-input-number
                        v-model:value="maxWorkHeightInMillimeters"
                        type="text"
                        :min="0"
                        placeholder="4000"
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Bauhöhe (mm)
                      </p>
                      <n-input-number
                        v-model:value="maxHeightInMillimeters"
                        type="text"
                        :min="0"
                        placeholder="5000"
                      >
                        <template #prefix>
                          {{ $t('general.max') }}
                        </template>
                      </n-input-number>
                    </div>
                    <div class="flex items-end">
                      <n-button class="!bg-white" @click="resetFilters">
                        Alle zurücksetzen
                      </n-button>
                    </div>
                  </div>

                  <div class="flex flex-col">
                    <div class="grid grid-cols-3 gap-2">
                      <div>
                        <p class="font-semibold">
                          Betriebsstunden
                        </p>
                        <div class="flex items-center">
                          <n-input-number
                            v-model:value="minOperatingHours"
                            type="text"
                            :min="0"
                            placeholder="0"
                          >
                            <template #prefix>
                              {{ $t('general.min') }}
                            </template>
                          </n-input-number>
                          <n-input-number
                            v-model:value="maxOperatingHours"
                            type="text"
                            :min="0"
                            placeholder="1000"
                          >
                            <template #prefix>
                              {{ $t('general.max') }}
                            </template>
                          </n-input-number>
                        </div>
                      </div>
                      <div class="flex items-end">
                        <n-checkbox v-model:checked="showMachineryLocationRow" type="checkbox" class="shrink-0">
                          <span class="font-semibold">
                            Geräte-Standort anzeigen
                          </span>
                        </n-checkbox>
                      </div>
                    </div>
                    <div class="my-4">
                      Eine 10%-Toleranz wird auf die Filter für Tragkraft und Hubhöhe angewendet
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                  <div class="flex flex-col">
                    <span class="text-lg mt-0">
                      {{ $t('calendar.filters.attachedMachineryAccessory.title') }}
                    </span>
                    {{ $t('calendar.filters.attachedMachineryAccessory.description') }}
                  </div>
                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        {{ $t('machineryAccessory.category.title') }}
                      </p>
                      <n-select
                        v-model:value="selectedMachineAccessoryCategory"
                        :options="machineryAttachedAccessoryCategoryOptions"
                        :consistent-menu-width="false"
                        filterable
                        clearable
                      />
                    </div>
                  </div>
                  <div v-if="selectedMachineAccessoryCategory === 'fork'" class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        {{ $t('general.length_mm') }}
                      </p>
                      <div class="flex items-center">
                        <n-input-number
                          v-model:value="minMachineAccessoryLengthInMillimeters"
                          type="text"
                          :min="0"
                          placeholder="0"
                        >
                          <template #prefix>
                            {{ $t('general.min') }}
                          </template>
                        </n-input-number>
                        <n-input-number
                          v-model:value="maxMachineAccessoryLengthInMillimeters"
                          type="text"
                          :min="0"
                          placeholder="1000"
                        >
                          <template #prefix>
                            {{ $t('general.max') }}
                          </template>
                        </n-input-number>
                      </div>
                    </div>
                    <div>
                      <p class="font-semibold">
                        {{ $t('general.width_mm') }}
                      </p>
                      <div class="flex items-center">
                        <n-input-number
                          v-model:value="minMachineAccessoryWidthInMillimeters"
                          type="text"
                          :min="0"
                          placeholder="0"
                        >
                          <template #prefix>
                            {{ $t('general.min') }}
                          </template>
                        </n-input-number>
                        <n-input-number
                          v-model:value="maxMachineAccessoryWidthInMillimeters"
                          type="text"
                          :min="0"
                          placeholder="1000"
                        >
                          <template #prefix>
                            {{ $t('general.max') }}
                          </template>
                        </n-input-number>
                      </div>
                    </div>
                    <div>
                      <p class="font-semibold">
                        {{ $t('general.height_mm') }}
                      </p>
                      <div class="flex items-center">
                        <n-input-number
                          v-model:value="minMachineAccessoryHeightInMillimeters"
                          type="text"
                          :min="0"
                          placeholder="0"
                        >
                          <template #prefix>
                            {{ $t('general.min') }}
                          </template>
                        </n-input-number>
                        <n-input-number
                          v-model:value="maxMachineAccessoryHeightInMillimeters"
                          type="text"
                          :min="0"
                          placeholder="1000"
                        >
                          <template #prefix>
                            {{ $t('general.max') }}
                          </template>
                        </n-input-number>
                      </div>
                    </div>
                  </div>
                  <div v-if="['sideShifter', 'forkAdjuster', 'forkCarriage'].includes(selectedMachineAccessoryCategory ?? '')" class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        {{ $t('machineryAccessory.producerCompanyName') }}
                      </p>
                      <n-input
                        v-model:value="machineAccessoryProducerCompanyName"
                        filterable
                        clearable
                      />
                    </div>
                    <div>
                      <p class="font-semibold">
                        {{ $t('machineryAccessory.liftingWeight') }}
                      </p>
                      <n-input-number
                        v-model:value="minMachineAccessoryLiftingWeight"
                        type="text"
                        :min="0"
                        placeholder="0"
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                  </div>
                  <div v-if="selectedMachineAccessoryCategory === 'charger'" class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        {{ $t('machineryAccessory.volt') }}
                      </p>
                      <n-input
                        v-model:value="machineAccessoryVolt"
                        filterable
                        clearable
                      />
                    </div>
                  </div>
                </div>
              </n-collapse-item>
            </n-collapse>
          </template>

          <TableView
            v-if="!errorMachinery && !errorCustomers"
            v-bind="machineryServerSidePaginationTableProps"
            :key="stringifiedMachineryFilters"
            :data="machinery"
            :columns="machineryColumns"
            :is-loading="isFetchingCustomers || isFetchingMachinery"
            :single-line="false"
            :row-key="row => row.id"
            :checked-row-keys="checkedMachineryKeys"
            class="mt-4"
            @update:checked-row-keys="keys => checkedMachineryKeys = keys"
          />
        </TableFilters>
      </TheDataCard>

      <!-- ItemSet -->
      <TheDataCard v-if="isOfferCreationMode">
        <h3 class="text-xl">
          Sets auswählen
        </h3>

        <TableFilters v-if="isItemSetAvailable" v-model="itemSetFulltextSearchValue" :placeholder="itemSetFulltextSearch.placeholder">
          <TableView
            v-if="!errorItemSet && !errorCustomers"
            v-bind="itemSetServerSidePaginationTableProps"
            :key="itemSetFulltextSearchValue"
            :data="itemSets"
            :row-props="itemSetRowProps"
            :columns="itemSetColumn"
            :is-loading="isFetchingCustomers || isFetchingItemSet"
            :single-line="false"
            :row-key="row => row.id"
            :checked-row-keys="checkedItemSetKeys"
            class="mt-4"
            @update:checked-row-keys="keys => checkedItemSetKeys = keys"
          />
        </TableFilters>
        <div v-else class="my-2">
          Bitte wählen Sie entweder eine Maschine aus oder hacken Sie "Nur Lagertool Geräte vermieten / verkaufen" an.
        </div>
      </TheDataCard>

      <!-- Machinery Accessories -->
      <TheDataCard v-if="!isOfferCreationMode && ['machineryAccessories', 'all'].includes(showCalendarFor)">
        <h3 class="text-xl">
          Anbau-Geräte
        </h3>
        <TableFilters v-model="machineryAccessoryFulltextSearchValue" class="MachineryTable" placeholder="Suchbegriff eingeben (ID, Hersteller, Typ, Kommentar, Lagerplatz, Artikelnummer, Registriert von)">
          <template #search>
            <n-collapse>
              <n-collapse-item>
                <template #header>
                  <span class="text-lg">
                    Anbau-Geräte-spezifische Filter ausklappen
                  </span>
                </template>
                <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        Kategorie
                      </p>
                      <n-select
                        v-model:value="selectedMachineryAccessoryCategories"
                        :options="machineryAccessoryCategorySelectOptions"
                        multiple
                        filterable
                        clearable
                      />
                    </div>
                    <div>
                      <p class="font-semibold">
                        Einfahrlaschen-IK-IK (mm)
                      </p>
                      <n-input-number
                        v-model:value="retractionLugsDistanceInnerEdgeToInnerEdge"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Artikelnummer
                      </p>
                      <n-input
                        v-model:value="productCode"
                        :consistent-menu-width="false"
                        filterable
                        clearable
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        Tragkraft (kg)
                      </p>
                      <div class="flex items-center">
                        <n-input-number
                          v-model:value="minAccessoryLiftingWeight"
                          :min="0"
                          :consistent-menu-width="false"
                          placeholder="50"
                          filterable
                          clearable
                        >
                          <template #prefix>
                            {{ $t('general.min') }}
                          </template>
                        </n-input-number>
                        <n-input-number
                          v-model:value="maxAccessoryLiftingWeight"
                          :min="0"
                          :consistent-menu-width="false"
                          placeholder="50"
                          filterable
                          clearable
                        >
                          <template #prefix>
                            {{ $t('general.max') }}
                          </template>
                        </n-input-number>
                      </div>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Einfahrschlaschne AK-AK (mm)
                      </p>
                      <n-input-number
                        v-model:value="retractionLugsDistanceOuterEdgeToOuterEdge"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Kommentar
                      </p>
                      <n-input
                        v-model:value="comment"
                        :consistent-menu-width="false"
                        filterable
                        clearable
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        {{ $t('calendar.filters.attachedMachineryAccessory.fem') }}
                      </p>
                      <n-select
                        v-model:value="fem"
                        :options="machineryAccessoryFEMSelectOptions"
                        multiple
                        filterable
                        clearable
                      />
                    </div>
                    <div>
                      <p class="font-semibold">
                        Einfahrlaschen-Breite (mm)
                      </p>
                      <n-input-number
                        v-model:value="retractionLugsWidth"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Antrieb
                      </p>
                      <n-input
                        v-model:value="drive"
                        :consistent-menu-width="false"
                        filterable
                        clearable
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        Länge (mm)
                      </p>
                      <div class="flex items-center">
                        <n-input-number
                          v-model:value="minAccessoryLengthInMillimeters"
                          :min="0"
                          :consistent-menu-width="false"
                          placeholder="50"
                          filterable
                          clearable
                        >
                          <template #prefix>
                            {{ $t('general.min') }}
                          </template>
                        </n-input-number>
                        <n-input-number
                          v-model:value="maxAccessoryLengthInMillimeters"
                          :min="0"
                          :consistent-menu-width="false"
                          placeholder="50"
                          filterable
                          clearable
                        >
                          <template #prefix>
                            {{ $t('general.max') }}
                          </template>
                        </n-input-number>
                      </div>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Einfahrlaschen-Höhe (mm)
                      </p>
                      <n-input-number
                        v-model:value="retractionLugsHeight"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Volt
                      </p>
                      <n-input
                        v-model:value="volt"
                        :consistent-menu-width="false"
                        filterable
                        clearable
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        Breite (mm)
                      </p>
                      <div class="flex items-center">
                        <n-input-number
                          v-model:value="minAccessoryWidthInMillimeters"
                          :min="0"
                          :consistent-menu-width="false"
                          placeholder="50"
                          filterable
                          clearable
                        >
                          <template #prefix>
                            {{ $t('general.min') }}
                          </template>
                        </n-input-number>
                        <n-input-number
                          v-model:value="maxAccessoryWidthInMillimeters"
                          :min="0"
                          :consistent-menu-width="false"
                          placeholder="50"
                          filterable
                          clearable
                        >
                          <template #prefix>
                            {{ $t('general.max') }}
                          </template>
                        </n-input-number>
                      </div>
                    </div>
                    <div>
                      <p class="font-semibold">
                        AK-AK (mm)
                      </p>
                      <n-input-number
                        v-model:value="distanceOuterEdgeToOuterEdge"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Reifengröße
                      </p>
                      <n-input
                        v-model:value="wheelSize"
                        :consistent-menu-width="false"
                        filterable
                        clearable
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        Höhe (mm)
                      </p>
                      <div class="flex items-center">
                        <n-input-number
                          v-model:value="minAccessoryHeightInMillimeters"
                          :min="0"
                          :consistent-menu-width="false"
                          placeholder="50"
                          filterable
                          clearable
                        >
                          <template #prefix>
                            {{ $t('general.min') }}
                          </template>
                        </n-input-number>
                        <n-input-number
                          v-model:value="maxAccessoryHeightInMillimeters"
                          :min="0"
                          :consistent-menu-width="false"
                          placeholder="50"
                          filterable
                          clearable
                        >
                          <template #prefix>
                            {{ $t('general.max') }}
                          </template>
                        </n-input-number>
                      </div>
                    </div>
                    <div>
                      <p class="font-semibold">
                        IK-IK (mm)
                      </p>
                      <n-input-number
                        v-model:value="distanceInnerEdgeToInnerEdge"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                    <div>
                      <p class="font-semibold">
                        Cbm
                      </p>
                      <n-input-number
                        v-model:value="cubicMeters"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <p class="font-semibold">
                        Gewicht (kg)
                      </p>
                      <n-input-number
                        v-model:value="weightInKilograms"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          {{ $t('general.min') }}
                        </template>
                      </n-input-number>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                  <div class="grid grid-cols-3 gap-2">
                    <div class="flex items-end">
                      <n-button color="#fff" text-color="#000" @click="resetAccessoryFilters">
                        Alle Filter zurücksetzen
                      </n-button>
                    </div>
                  </div>
                  <p class="my-2">
                    Nicht alle Anbau-Geräte haben alle hier aufgelisteten Eigenschaften. Wenn Sie nach einer Eigenschaft suchen, die ein Anbau-Gerät nicht hat, wird dieses Anbau-Gerät herausgefiltert.
                  </p>
                </div>
              </n-collapse-item>
            </n-collapse>
          </template>
          <TableView
            v-if="!errorMachineryAccessory"
            :key="stringifiedMachineryAccessoryFilters"
            :data="machineryAccessory"
            :columns="machineryAccessoryColumns"
            :is-loading="isFetchingMachineryAccessory"
            :single-line="false"
            :row-key="row => row.id"
            :show-summary="false"
            class="mt-4"
            v-bind="accessoryServerSidePaginationTableProps"
          />
        </TableFilters>
      </TheDataCard>
    </div>

    <div class="flex mt-4 gap-4">
      <ShoppingAccessoryOverview
        v-if="isOfferCreationMode"
        v-model="cartPositions"
        class="w-2/3 gap-4 overflow-scroll"
        :show-attachable-tab="selectedMachineryToAdd?.category === 'forklift'"
        :machinery-id="selectedMachineryToAdd?.id"
        :offer-start-day="new Date(selectedStartTime)"
        :offer-end-day="rentalEndDate"
        :selected-tab="shoppingTab"
        :selected-machinery-accessory-category="offerType !== 'special' ? shoppingAccessoryCategory : 'storageSpace'"
        :is-category-select-disabled="offerType === 'special'"
        :is-fork-edit-mode="isForkEditMode"
        :is-accessory-only-offer="isAccessoryOnlyOffer"
        :offer="{
          status: offerCreationMode.offerStatus ?? 'offer',
          id: offerCreationMode.offerId ?? undefined,
        }"
        :disabled-accessory-ids="disabledAccessoryIds"
        @open-accessory-id-search-popup="showAccessoryIdSearchPopup = true"
        @select-tab="selectShoppingTab"
        @select-machinery-accessory-category="selectShoppingAccessoryCategory"
        @update-is-fork-edit-mode="updateIsForkEditMode"
      />

      <div v-if="isOfferCreationMode" class="w-1/3 flex flex-col gap-2">
        <TheDataCard>
          <p v-if="offerCreationMode.originalOfferId" class="font-semibold">
            Sie sind dabei "{{ offerCreationMode.originalOfferId }}" zu kopieren. Alle Auftragspositionen außer das Gerät und die Anbau-Geräte werden übernommen.
          </p>

          <div class="flex gap-2 mt-4">
            <n-button
              v-if="['rental', 'service-project', 'special'].includes(offerType)"
              type="primary"
              class="grow"
              :disabled="!allowAddToOffer"
              @click="emitAddToOffer"
            >
              <template #icon>
                <Icon :name="useBrandIcons().rental" />
              </template>
              Konfiguration vermieten
            </n-button>
            <n-button v-if="offerType === 'sale'" type="primary" class="grow" :disabled="!allowAddToOffer" @click="emitAddToOffer">
              <template #icon>
                <Icon :name="useBrandIcons().sale" />
              </template>
              Konfiguration verkaufen
            </n-button>
          </div>
        </TheDataCard>

        <!-- Offer Creation Mode: Machinery Accessory Selection -->
        <ShoppingCart
          v-model="cartPositions"
          :is-fork-edit-mode="isForkEditMode"
          :selected-machinery-to-add="selectedMachineryToAdd"
          :details-for-machinery-accessories-in-cart="detailsForMachineryAccessoriesInCart ?? []"
          :selected-item-sets-to-add="selectedItemSetsToAdd"
          :offer-start-day="new Date(selectedStartTime)"
          :offer-end-day="rentalEndDate"
          :offer="{
            status: offerCreationMode.offerStatus ?? 'offer',
            id: offerCreationMode.offerId ?? undefined,
          }"
          :disabled-accessory-ids="disabledAccessoryIds"
          :is-accessory-only-offer="isAccessoryOnlyOffer"
          @select-tab="selectShoppingTab"
          @select-machinery-accessory-category="selectShoppingAccessoryCategory"
          @update-is-fork-edit-mode="updateIsForkEditMode"
          @remove-set="removeSet"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Remove all padding from table  cells. NOTE: VS Code doesn't understand this syntax, although it's CSS native: https://blog.logrocket.com/native-css-nesting/ */
.MachineryTable {
  & :deep(.n-data-table-td) {
    @apply p-0 !important
  }
  & :deep(.n-data-table-th) {
    @apply p-0 !important
  }
  & :deep(td.TodayColumn > div) {
    @apply border-x-2 border-black
  }
  & :deep(tr:last-of-type > td.TodayColumn > div) {
    @apply border-b-2
  }
}
</style>
