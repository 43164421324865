import revive_payload_client_N4tIvGJpMs from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.1_ioredis@5._6twrl6vsdfgigkhxtfyg3zqu5m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IcZoe7lK3U from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.1_ioredis@5._6twrl6vsdfgigkhxtfyg3zqu5m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TdORvhk4DW from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.1_ioredis@5._6twrl6vsdfgigkhxtfyg3zqu5m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import payload_client_Y2jgcnaIHi from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.1_ioredis@5._6twrl6vsdfgigkhxtfyg3zqu5m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_IZ80ulViqf from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.1_ioredis@5._6twrl6vsdfgigkhxtfyg3zqu5m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_m5o0aXKcS7 from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.1_ioredis@5._6twrl6vsdfgigkhxtfyg3zqu5m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_EzLdhgbFDr from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.1_ioredis@5._6twrl6vsdfgigkhxtfyg3zqu5m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tOEre7Svmw from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.16_magicast@0.3.4_rollup@4.18.1_webpack-sources@3.2.3_webpack@5.92.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import switch_locale_path_ssr_BmBFr3pDoS from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.18.1_vue@3.4.32_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_zeHQYJzM4k from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.18.1_vue@3.4.32_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import plugin_ANxwH0nuno from "/app/node_modules/.pnpm/@nuxt+icon@1.7.5_magicast@0.3.4_rollup@4.18.1_vite@5.3.4_@types+node@22.9.0_terser@5.31.6__vu_axtceat5el3xufwwv5mw3zloee/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import colorMode_8O6UHnGENV from "/app/node_modules/.pnpm/@bg-dev+nuxt-naiveui@1.14.0_magicast@0.3.4_rollup@4.18.1_vue@3.4.32_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@bg-dev/nuxt-naiveui/dist/runtime/plugins/colorMode.mjs";
import plugin_f7PdehqtId from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_encoding@0.1.13_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+_23djppy5ki5xgoq4gsf4cwwf5m/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import chunk_reload_client_BG2S3BHLQY from "/app/plugins/chunk-reload.client.ts";
import signaturePad_StdQn7RF4b from "/app/plugins/signaturePad.ts";
import trpcClient_M8UiGTyzsx from "/app/plugins/trpcClient.ts";
import vue_query_wrmMkNpEpe from "/app/plugins/vue-query.ts";
export default [
  revive_payload_client_N4tIvGJpMs,
  unhead_IcZoe7lK3U,
  router_TdORvhk4DW,
  sentry_client_shVUlIjFLk,
  payload_client_Y2jgcnaIHi,
  navigation_repaint_client_IZ80ulViqf,
  check_outdated_build_client_m5o0aXKcS7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EzLdhgbFDr,
  plugin_tOEre7Svmw,
  switch_locale_path_ssr_BmBFr3pDoS,
  i18n_zeHQYJzM4k,
  formkitPlugin_pZqjah0RUG,
  plugin_ANxwH0nuno,
  colorMode_8O6UHnGENV,
  plugin_f7PdehqtId,
  chunk_reload_client_BG2S3BHLQY,
  signaturePad_StdQn7RF4b,
  trpcClient_M8UiGTyzsx,
  vue_query_wrmMkNpEpe
]