<script setup lang="ts">
import type { z } from 'zod'
import type { createInnerMachineryAccessory } from '~/server/schemas'
import type { UpdateMachineryAccessory, UpdateOrCreateMachineryAccessory } from '~/types'

const { openAccessoryCreateOrEditPopup: { data: popupData, close: closePopup_ } } = useGlobalOpeners()

const { machineryAccessory: queryMachineryAccessory, machineryType: queryMachineryType } = useQuery()
const accessoryId = computed(() => {
  if (popupData.value?.mode === 'update') {
    return popupData.value.id
  }
  return undefined
})
const { data: accessory, isLoading: isLoadingAccessory } = queryMachineryAccessory.byId(accessoryId)
const { data: machineryTypes } = queryMachineryType.all()

const { key: localStorageKey, customKeyInfix, clearFormStorage } = useLocalStorageKeyForFormKit()

// to return created id
const popupReceptionMachinery = useRouteQueryAsObject('machineryReceptionPopup')
function closePopup(createdId?: string) {
  if (createdId) {
    popupReceptionMachinery.value = {
      ...popupReceptionMachinery.value,
      accessoryId: createdId,
    }
  }
  clearFormStorage()
  closePopup_()
}

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const update = useMutation({
  mutationFn: $trpc.machineryAccessory.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Anbaugerät konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['accessories'] })
    closePopup()
    notification.success({ title: 'Das Anbaugerät wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const create = useMutation({
  mutationFn: $trpc.machineryAccessory.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Anbaugerät konnte nicht erstellt werden' }),
  onSuccess: async (accessory) => {
    await queryClient.invalidateQueries({ queryKey: ['accessories'] })
    if (popupData.value?.mode === 'create' && popupData.value.isCreatedIdRequired) {
      closePopup(accessory.id)
    } else {
      closePopup()
    }
    notification.success({ title: `Das Anbaugerät wurde erfolgreich erstellt: ${accessory.id}`, duration: 4500 })
  },
})

function saveCreateOrEditForm(payload: UpdateOrCreateMachineryAccessory) {
  if (payload.mode === 'update') {
    return update.mutateAsync(payload.data)
  } else {
    return create.mutateAsync(payload.data)
  }
}

const { machineryAccessoryFieldsByCategory } = useFormOptionalConditions()

const payload = ref<UpdateOrCreateMachineryAccessory | undefined>()

watch(
  () => ({ popupDataValue: popupData.value ?? null, accessoryValue: accessory.value ?? null, isLoadingAccessoryValue: isLoadingAccessory.value }),
  ({ popupDataValue, accessoryValue, isLoadingAccessoryValue }) => {
    // Don't override existing `payload` if already set
    if (payload.value) {
      return
    }

    if (!popupDataValue) {
      return undefined
    }

    if (popupDataValue.mode === 'create') {
      const category = popupDataValue.category
      // Separate forms in localStorage by `category`
      customKeyInfix.value = category

      payload.value = {
        mode: 'create',
        data: {
          accessory: {
            category,
            storageLocation: null,
            priceInEuros: 0,
            condition: 'neu',
            transportLengthInMillimeters: 0,
            transportWidthInMillimeters: 0,
            transportHeightInMillimeters: 0,
            comment: '',
            photos: [],
            isDefective: false,
            isReleasedForOnlineSale: false,
            ...(machineryAccessoryFieldsByCategory.producerCompanyName.includes(category) && {
              producerCompanyName: '',
            }),
            ...(machineryAccessoryFieldsByCategory.tireType.includes(category) && {
              tireType: 'Luft',
            }),
            ...(machineryAccessoryFieldsByCategory.color.includes(category) && {
              color: 'Weiß',
            }),
            ...(machineryAccessoryFieldsByCategory.typeName.includes(category) && {
              typeName: '',
            }),
            ...(machineryAccessoryFieldsByCategory.deliveryCompanyName.includes(category) && {
              deliveryCompanyName: '',
            }),
            ...(machineryAccessoryFieldsByCategory.trailerType.includes(category) && {
              trailerType: 'standard',
            }),
            ...(machineryAccessoryFieldsByCategory.licensePlateNumber.includes(category) && {
              licensePlateNumber: '',
            }),
            ...(machineryAccessoryFieldsByCategory.firstRegistrationDate.includes(category) && {
              firstRegistrationDate: null,
            }),
            ...(machineryAccessoryFieldsByCategory.technicalInspectionDate.includes(category) && {
              technicalInspectionDate: null,
            }),
            ...(machineryAccessoryFieldsByCategory.securityInspectionDate.includes(category) && {
              securityInspectionDate: null,
            }),
            ...(machineryAccessoryFieldsByCategory.vehicleRegistrationPhoto.includes(category) && {
              vehicleRegistrationPhoto: [],
            }),
            ...(machineryAccessoryFieldsByCategory.serialNumber.includes(category) && {
              serialNumber: '',
            }),
            ...(machineryAccessoryFieldsByCategory.imeiOne.includes(category) && {
              imeiOne: null,
            }),
            ...(machineryAccessoryFieldsByCategory.imeiTwo.includes(category) && {
              imeiTwo: null,
            }),
            ...(machineryAccessoryFieldsByCategory.lengthInMillimeters.includes(category) && {
              lengthInMillimeters: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.widthInMillimeters.includes(category) && {
              widthInMillimeters: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.heightInMillimeters.includes(category) && {
              heightInMillimeters: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.fem.includes(category) && {
              fem: '1',
            }),
            ...(machineryAccessoryFieldsByCategory.yearBuilt.includes(category) && {
              yearBuilt: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.liftingWeight.includes(category) && {
              liftingWeight: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.weightInKilograms.includes(category) && {
              weightInKilograms: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.forkLength.includes(category) && {
              forkLength: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.forkWidth.includes(category) && {
              forkWidth: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.forkHeight.includes(category) && {
              forkHeight: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.distanceInnerEdgeToInnerEdge.includes(category) && {
              distanceInnerEdgeToInnerEdge: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.cubicMeters.includes(category) && {
              distanceOuterEdgeToOuterEdge: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.distanceOuterEdgeToOuterEdge.includes(category) && {
              cubicMeters: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.retractionLugsWidth.includes(category) && {
              retractionLugsWidth: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.retractionLugsHeight.includes(category) && {
              retractionLugsHeight: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.retractionLugsDistanceInnerEdgeToInnerEdge.includes(category) && {
              retractionLugsDistanceInnerEdgeToInnerEdge: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.retractionLugsDistanceOuterEdgeToOuterEdge.includes(category) && {
              retractionLugsDistanceOuterEdgeToOuterEdge: 0,
            }),
            ...(machineryAccessoryFieldsByCategory.description.includes(category) && {
              description: '',
              liftingHeight: 0,
              componentParts: '',
              drive: '',
              lengthLeverLink: 0,
              widthLeverLink: 0,
              powerLeverLink: 0,
            }),
          } as z.infer<typeof createInnerMachineryAccessory>,
          machineryTypes: [],
        },
      }
    }

    if (popupDataValue.mode === 'update') {
      if (!accessoryValue || isLoadingAccessoryValue) {
        return
      }

      // Separate forms in localStorage by `id`
      customKeyInfix.value = accessoryId.value

      payload.value = {
        mode: 'update',
        data: {
          accessory: accessoryValue,
          machineryTypes: accessoryValue.compatibleMachineryTypes.map(type => type.id) ?? [],
        },
      } as UpdateMachineryAccessory
    }
  },
  {
    immediate: true,
  },
)

const showStorageLocation = computed(() => popupData.value?.mode === 'create' && !popupData.value.isCreatedIdRequired)
const showInspectionDates = computed(() => popupData.value?.mode === 'create')
</script>

<template>
  <ThePopup v-if="payload" :show="Boolean(payload)" title="Anbaugeräte-Anlage" @close="closePopup">
    <MachineryAccessoryCreationCreateOrEditForm
      :payload="payload"
      :machinery-types="machineryTypes ?? []"
      :local-storage-key="localStorageKey"
      :show-storage-location="showStorageLocation"
      :show-inspection-dates="showInspectionDates"
      @save="saveCreateOrEditForm"
    />
  </ThePopup>
</template>
